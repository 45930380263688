<template>
  <auth-wrap class="login">
    <div class="login--content">
      <h2 class="login--title-one">Bienvenue sur OBY</h2>
      <h3 class="login--title-two" v-text="appTitle"></h3>
      <form class="login--form">
        <div class="mt-auto">
          <div class="p-2 form-group custom-form custom-form--input input--login" v-if="loginError">
            <slot name="error">
              <small class="login--error">{{ loginError }}</small>
            </slot>
          </div>
          <div class="p-2 custom-form--input active-value-label input--login">
            <form-input
              :error="errors.email"
              label="Login"
              :login-input="false"
              v-model="email"
              type="email"
              :required="true"
              @input="inputEmail"
            />
          </div>
          <div class="p-2 custom-form--input mt-1 active-value-label input--login">
            <form-input
              :error="errors.password"
              label="Mot de passe"
              :login-input="false"
              v-model="password"
              type="password"
              :required="true"
              @input="inputPassword"
            />
          </div>
        </div>
        <div class="mt-auto d-flex justify-content-center">
          <button class="login__btn"
                  style="min-width: 187px;"
                  :disabled="disabled"
                  @click.prevent="handlerLogin">
            <font-awesome-icon v-show="loading" :icon="['fas', 'spinner']" spin/> Connexion</button>
        </div>
        <div class="d-flex justify-content-center login__deconexion">
          <a href="#" @click.prevent="$router.push({name: 'forgotPassword'})">Mot de passe oublié</a>
        </div>
      </form>
      <div class="spinner" id="spinner"></div>
      <div class="login__container">
        <hr class="login__divider"/>
        <p class="login__text">ou</p>
        <hr class="login__divider"/>
      </div>
      <button class="login__button" @click.prevent="openPopup()"><img alt="logo pro santé connect" src="@/assets/login_pro_sante_connect.svg" /></button>
    </div>
    <Cgu  v-show="cgu"/>
    <Mention  v-show="mention"/>
    <Rgpd  v-show="rgpd"/>
  </auth-wrap>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import FormInput from "../../components/Form/Input.vue";
  import Cgu from "../../components/CGU/Cgu.vue";
  import Mention from "../../components/CGU/Mention.vue";
  import Rgpd from "../../components/CGU/Rgpd.vue";

  export default {
    name: "LoginPage",
    components: {Rgpd, Mention, Cgu, FormInput},
    data() {
      return {
        email: null,
        password: null,
        errors: {
          email: "",
          password: "",
        },
        loginError: null,
        loading: false,
      };
    },
    mounted: function ()
    {
      const fullUrl = window.location.href;
      const queryStart = fullUrl.indexOf('?');

      if (queryStart !== -1)
      {
        const queryString = fullUrl.substring(queryStart + 1);

        const urlParams = new URLSearchParams(queryString);
        const logout = urlParams.get('logout');

        if (logout === '1') {
          this.$store.commit('setConnectedUser', null);
          this.$store.state.User.state.user = null;

          sessionStorage.clear();

        }
      }

      this.getUserConnected().then( () => {
        if (this.connectedUser) {
          this.$router.push({name: 'client-list'});
        }
      })
    },
    computed: {
      ...mapState('User', ['connectedUser']),
      ...mapState("Cgu", ['termsAccepted']),
      userNotEmpty() {
        return this.email !== null && this.email !== "" && this.password !== null && this.password !== "";
      },
      disabled() {
        return !this.userNotEmpty || this.loading;
      },
      appTitle() {
        if (this.$route.path.includes('evaluation')) {
          return 'Votre outil d\'évaluation'
        } else {
          return 'Back Office d’administration'
        }
      }
    },
    watch: {
      termsAccepted() {
        if (this.termsAccepted) {

          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          if (this.$route.path.includes('evaluation')) {
            if (permissionsArray.includes('CAN_SEARCH_CLIENT')) {
              this.$router.push({name: 'accueil'});
            } else if (permissionsArray.includes('CAN_BACK_OFFICE')) {
              this.$router.push({name: 'client-list'});
            }
          } else {
            if (permissionsArray.includes('CAN_BACK_OFFICE')) {
              this.$router.push({name: 'client-list'});
            } else if (permissionsArray.includes('CAN_SEARCH_CLIENT')) {
              this.$router.push({name: 'accueil'});
            }
          }

          if (!permissionsArray.includes('CAN_BACK_OFFICE') && !permissionsArray.includes('CAN_SEARCH_CLIENT')) {
            this.$router.push({name: 'accueil'});
          }
        }
      }
    },
    methods: {
      ...mapActions('User', ["loginUser", "loginUserPSC", "redirectPSC", 'getUserConnected']),
      ...mapActions('Cgu', ['getTerms']),
      handlerLogin() {
        this.loading = true;
        this.loginUser({email: this.email, password: this.password}).then(() => {

          this.loading = false;
          this.errors = {};
          this.loginError = null;
        }).catch((err) => {
          this.loading = false;
          this.errors.email = ' ';
          this.errors.password = ' ';
          this.loginError = '* Login et mot de passe incorrects';
          throw err;
        });
      },
      showSpinner() {
        const spinner = document.getElementById('spinner');
        spinner.style.display = 'block';
      },
      hideSpinner() {
        const spinner = document.getElementById('spinner');
        spinner.style.display = 'none';
      },
      openPopup() {
        this.redirectPSC({}).then((res) => {
          const url = res.redirectUrl;
          const width = 800;
          const height = 600;
          const left = (window.screen.width / 2) - (width / 2);
          const top = (window.screen.height / 2) - (height / 2);
          const popupFeatures = `width=${width},height=${height},scrollbars=yes,resizable=yes,left=${left},top=${top}`;
          const popup = window.open(url, 'popupWindow', popupFeatures);

          const checkPopupClosed = setInterval(() => {
          if (popup.closed) {
            clearInterval(checkPopupClosed);
            this.onPopupClosed();
          }
        }, 500);
        });
      },
      onPopupClosed() {
        this.showSpinner();
        sessionStorage.setItem('PSC_AUTO_LOGOUT', Date.now().toString());

        this.loginUserPSC().then((res) => {
          if (res == "NO MATCH") {
            this.$router.push({name: 'loginPsc'})
            return;
          }

          this.loginUser({email: res, password: 'connectPSC'}).then((res) => {
          if (this.$route.path.includes('evaluation')) {
            this.$router.push({name: 'accueil'});
          } else {
            this.$router.push({name: 'client-list'});
          }

          this.loading = false;
          this.errors = {};
          this.loginError = null;
          this.hideSpinner();
          return res;
        }).catch((err) => {
          this.loading = false;
          this.errors.email = ' ';
          this.errors.password = ' ';
          this.loginError = '* Login et mot de passe incorrects';
          this.hideSpinner();
          throw err;
        })})
      },
      inputEmail() {
        this.errors.email = '';
        this.loginError = null;
      },
      inputPassword() {
        this.errors.password = '';
        this.loginError = null;
      },
    }
  };
</script>
<style scoped>
  .spinner {
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top: 8px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    transform: translate(-50%, -50%);
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>