<template>
  <custom-modal :active="this.toggleModal && this.connectedUser" :crossClose="false" :bgDark="false" :id="'custom-modal-materials'">
    <div v-html="this.terms.content"></div>
    <div class="cguModal__container">
      <b-button class="mt-2" variant="btn col-3 btn-primary" @click.prevent="acceptedTerms">J'accepte</b-button>
      <b-button class="mt-2" variant="btn col-3 btn-outline-primary" @click.prevent="dontAcceptedTerm">Je refuse</b-button>
    </div>
  </custom-modal>
</template>
<script>
import {mapActions, mapState} from "vuex";
import CustomModal from "../Util/Modal.vue";

export default {
  name: "cguModal",
  components: {CustomModal},
  computed: {
    ...mapState('User', ['connectedUser']),
    ...mapState("Cgu", ['terms', 'termsAccepted', 'toggleModal'])
  },
  methods: {
    ...mapActions("Cgu", ['getTerms', 'getTermsAccepted', 'postTermsAccepted']),
    ...mapActions("User", ["logout", "logoutPSC"]),
    acceptedTerms() {
      this.postTermsAccepted({ version: this.terms.termsId });
    },
    dontAcceptedTerm(){
      this.$notify.error("Vous devez accepter les CGU pour continuer à utiliser OBY évaluation. Vous avez été déconnecté.");
      this.logoutPSC();
      this.logout().then((res) => {
        this.$router.push({name: "login"});
        return res;
      }).catch((err) => {
        this.$router.push({name: "login"});
        throw err;
      });
    },
  },
  watch: {
    connectedUser(newValue) {
      if (newValue) {
        this.getTerms({ lang: 'fr' });
      }
    },
    terms(newValue) {
      if (newValue) {
        this.getTermsAccepted({ version: this.terms.termsId });
      }
    }
  },
}
</script>
<style scoped>
.cguModal__container {
  padding-top: 12px;
  display: flex;
  justify-content: center;
  gap: 8px;
}
</style>