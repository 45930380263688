import { API_URL } from "@/components/Util/EnvVariable";
import { postAsApplicationJson } from '@/api/Fetch';

export const getGenerateTemplate = async ({ commit }, { payload }) => {
    try {
        const response = await postAsApplicationJson(`${API_URL}/generate-template`, payload);
        const data = await response.json();
        commit("getGenerateTemplate", data);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getGenerateAnswerTemplate = async ({ commit }, { payload }) => {
    try {
        const response = await postAsApplicationJson(`${API_URL}/generate-answer-template`, payload);
        const data = await response.json();
        commit("getGenerateAnswerTemplate", data);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export default {
    getGenerateTemplate,
    getGenerateAnswerTemplate
};