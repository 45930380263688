<template>
    <div class="container pt-4">
      <h5 class="eval-item--title">Contact</h5>
      <div class="eval-item--card cr--context">
        <p>Veuillez préciser les personnes de l'entourage à qui le récapitulatif de la synthèse pourra être envoyé. Pour cela, il suffit d'appuyer sur les personnes concernées.</p>
        <div class="cr--context--contact">
          <div class="col-sm-12 col-md-4" v-for="contact in contacts" :key="contact.id" @click="toggleContact(contact.id)" >
            <eval-contact-card
                :contact="contact"
                :active="activedContacts.includes(contact.id)"
                :class="styleClass(contact.type)"
                :isEditable="false"
            />
          </div>
        </div>
        <div class="contact-actived">
          <p>Le compte-rendu de la synthèse sera transmis à :</p>
          <p>{{activedContactsNames}}</p>
        </div>
      </div>
    </div>
</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import CRComponent from "./CRComponent";
import EvalContactCard from "@/components/Eval/EvalContactCard";
import html2canvas from "html2canvas";


export default {
  name: "CR_Contact",
  extends: CRComponent,
  data() {
    return {
      activeContacts: []
    }
  },
  components: {
    EvalContactCard
  },
  created() {
    this.DEFAULT_VALUE = 'non renseigné';
  },
  methods: {
    ...mapMutations('Evaluation', ['setSyntheseGoalProperty']),
    ...mapActions('Evaluation', ['saveSyntheseGoals']),
    ...mapActions("Contact", ["getAllContacts"]),
    toggleContact(contactId) {
      this.activeContacts = [...this.activedContacts];
      if(this.activeContacts.includes(contactId)) {
        const index = this.activeContacts.indexOf(contactId);
        this.activeContacts.splice(index, 1);
      } else {

        this.activeContacts.push(contactId);
      }
      this.saveGoalsActions({
        problem: "",
        item: "contact-selected",
        goal: this.activeContacts,
        actions: "",
        affectation: "",
        active: true
      });
    },
    saveGoalsActions(data) {
      let itemInfos = data.item.split('-');
      const category = itemInfos[0];
      let subItem = null;
      if(itemInfos.length === 3) {
        subItem = itemInfos[1];
      }
      const name = itemInfos[itemInfos.length - 1];
      this.setSyntheseGoalProperty({
        item: data.item,
        problem: data.problem,
        goal: data.goal,
        actions: data.actions,
        affectation: data.affectation,
        active: data.active
      });
      this.saveSyntheseGoals({
        name: name,
        category: category,
        subItem: subItem
      });
    },
    async exportSynthesis() {
      const imgData = await this.handleCapture();
      return {
        contactImg: imgData,
        activedContactsNames: this.activedContactsNames
      }
    },
    styleClass(type) {
      if (type === 'entourage') {
        return '';
      } else if (type === 'care') {
        return 'theme-green';
      } else {
        return 'theme-red';
      }
    },
    async handleCapture() {
      const captureContainer = document.querySelector(".cr--context--contact");
      const canvas = await html2canvas(captureContainer, {
        backgroundColor: '#ffffff',
        allowTaint: false,
        useCORS: true,
        scrollX: 0,
        scrollY: -window.scrollY
      })
      if(!canvas) {
        return null;
      }
      return canvas.toDataURL("image/jpg");

    }

  },
  watch: {
    client(newClient) {
      if (newClient && newClient.id) {
        this.getAllContacts({ id: newClient.id });
      }
    }
  },
  computed: {
    ...mapState("Evaluation", ["evaluation","contact"]),
    ...mapState("Client", ["client"]),
    ...mapState("Contact", ["allContacts"]),
    contacts() {
      return this.allContacts
    },
    activedContactsNames() {
      const contactsIds = this.activedContacts;
      if(!this.allContacts) {
        return "";
      }

      const contactList = this.allContacts.reduce((acc, contact) => {
        if (contactsIds.includes(contact.id)) {
          return [...acc,` ${contact.name} ${contact.lastname}`]
        }
        return acc;
      }, []);
      if(contactList.length === 0) {
        return "non renseigné";
      }

      return `${contactList.slice(0, contactList.length - 1).join(",")} ${contactList.length > 1 ? "et" : ""} ${contactList[contactList.length - 1]}`;
    },
    activedContacts() {
      return this.contact.selected.goal;
    }
  }
};
</script>