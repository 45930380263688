<template>
  <div class="container pt-4">
    <h5 class="eval-item--title">Habitat et environnement de proximité</h5>
    <div class="eval-item--card cr--context">
      <div class="row">
        <div class="col-sm-12 cr_entry">
          <p>Type de logement :</p>
          <p class="response">{{getTypeLogement(habitat.inputs[1].response, countRoom(habitat.inputs[5].response), habitat.inputs[4].response, habitat.inputs[0].response)}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 cr_entry">
          <p>Accès au logement :</p>
          <p class="response">{{habitat.inputs[3].response}}</p>
        </div>
      </div>
      <div v-for="(value, index) in habitat.inputs[7].response.split(',')" :key="index" class="row">
        <div class="col-sm-12 cr_entry">
          <p v-if="value.split('=')[0] !== 'Non renseigné'">Eléments posant des difficultés dans la/le {{getLogementText(value)}} :</p>
          <p class="response">{{getLogementTextComment(value)}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 cr_entry">
          <p>Commerces et services de proximité ( à moins de 10 mn de marche) :</p>
          <p class="response">{{habitat.inputs[9].response}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 cr_entry">
          <p>Moyens de transport à proximité (a moins de 10 mn à pied) :</p>
          <p class="response">{{habitat.inputs[10].response}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <p class="mb-0">Commentaires de l'évaluateur sur l'habitat et l'environnement de proximité :</p>
          <textarea class="form-control" v-model="habitat.inputs[11].response"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CRComponent from "./CRComponent";
import {mapActions} from "vuex";

export default {
  name: "CR_Demand",
  extends: CRComponent,
  data() {
    return {
      DEFAULT_VALUE: '--',
      habitat: [],
    };
  },
  mounted() {
    const payload = {
      page: {
        folder: "social",
        domains: ["habitat"],
        evaluationId: this.$route.params.evaluationId,
        issuesElement: [],
      }
    };
    this.loadData(payload);
  },
  methods: {
    ...mapActions("GenerateTemplate", ["getGenerateTemplate", "getGenerateAnswerTemplate"]),
    countRoom(allRooms) {
      const rooms = allRooms.split(',');
      let arrayRoom = 0;
      rooms.forEach(t => {
        if (t.includes("Séjour") || t.includes("Chambre"))
          arrayRoom = parseInt(t?.split("=")[1]) + arrayRoom
      })
      return arrayRoom;
    },
    getLogementText(value) {
      const firstPart = value.split("=")[0];
      const withoutLastChar = firstPart.slice(0, -1);
      const lastChar = firstPart.slice(-1);

      return `${withoutLastChar} ${lastChar}`;
    },
    getLogementTextComment(value) {
      const comment = value?.split("=");

      if (comment.length > 1) {
        return value?.split("=")[1];
      }

      return 'Non renseigné';
    },
    getTypeLogement(type, count, surface, milieu) {
      return type + " de " + count + " pièces d'une surface de " + surface + ", en milieu " + milieu;
    },
    getIssuesRooms(synthesis) {
      const rooms = {};

      synthesis.forEach(input => {
        const [key, value] = input.split('=');
        const label = key.replace(/\d+$/, '').trim();

        if (!rooms[label]) {
          rooms[label] = [];
        }

        rooms[label].push(`${this.getLogementText(key)} : ${value || 'Non renseigné'}`);
      });

      Object.keys(rooms).forEach(key => {
        rooms[key] = [rooms[key].join(', ')];
      });

      return rooms;
    },
    exportSynthesis() {
      const synthesis = {};

      const payload = {
        page: {
          folder: "social",
          domains: ["habitat"],
          evaluationId: this.$route.params.evaluationId,
        }
      };
      this.loadData(payload);

      this.habitat.inputs?.forEach(input => {
        synthesis[input.id] = input.response || 'Non renseigné';
      });

      const rooms = this.getIssuesRooms(synthesis[75].split(','));

      return {
        logement: this.getTypeLogement(synthesis[11], this.countRoom(synthesis[73]), synthesis[14], synthesis[10]),
        logementAccess: synthesis[13],
        sejourDifficulties: rooms["Séjour"] || 'Non renseigné',
        bedroomDifficulties : rooms["Chambre"] || 'Non renseigné',
        bathroomDifficulties: rooms["SDB"] || 'Non renseigné',
        toiletDifficulties: rooms["WC"] || 'Non renseigné',
        couloirsDifficulties: rooms["Couloirs"] || 'Non renseigné',
        closeShops: synthesis[76],
        closeTransports: synthesis[23],
        habitatComment: synthesis[77],
      }
    },
    mapInputsWithResponses(inputs, responsesList) {
      return inputs.map(input => {
        const responses = responsesList?.filter(res => res.questionId === input.id);
        const responseValue = responses?.length > 0
            ? responses.map(res => res.responseValue).join(', ')
            : input.defaultComment;

        return {
          ...input,
          response: responseValue
        };
      });
    },

    async loadData(payload) {
      try {
        const data = await this.getGenerateTemplate({ payload });
        const responses = await this.getGenerateAnswerTemplate({ payload });

        if (Array.isArray(data)) {
          this.habitat = data.map(section => {
            return {
              ...section,
              inputs: this.mapInputsWithResponses(section.inputs, responses[0].responsesList ?? [])
            };
          })[0];
          this.issuesElement = this.habitat.inputs[7].response.split(",");
        } else {
          console.error('data n\'est pas un tableau', data);
        }
      } catch (error) {
        console.error('Erreur lors du chargement des données :', error);
      }
    }
  }
};
</script>