<template>
    <FormSelect
        v-model="nationality"
        :label="label"
        :error="error ? 'La nationalité est obligatoire.' : null"
        :disabled="disabled"
        :login-input="false"
        @input="onSelect"
    >
        <template v-slot:first>
          <optgroup label="France">
            <option value="Français">Français</option>
          </optgroup>
          <optgroup label="Autres pays européens">
            <option value="Allemand">Allemand</option>
            <option value="Andorran">Andorran</option>
            <option value="Belge">Belge</option>
            <option value="Espagnol">Espagnol</option>
            <option value="Italien">Italien</option>
            <option value="Luxembourgeois">Luxembourgeois</option>
            <option value="Monégasque">Monégasque</option>
            <option value="Néerlandais">Néerlandais</option>
            <option value="Suisse">Suisse</option>
            <option value="Autrichien">Autrichien</option>
            <option value="Portugais">Portugais</option>
            <option value="Grec">Grec</option>
            <option value="Irlandais">Irlandais</option>
            <option value="Finlandais">Finlandais</option>
            <option value="Suédois">Suédois</option>
          </optgroup>
          <optgroup label="Afrique">
            <option value="Algérien">Algérien</option>
            <option value="Marocain">Marocain</option>
            <option value="Tunisien">Tunisien</option>
            <option value="Sud-Africain">Sud-Africain</option>
            <option value="Nigérian">Nigérian</option>
            <option value="Égyptien">Égyptien</option>
            <option value="Sénégalais">Sénégalais</option>
            <option value="Ivoirien">Ivoirien</option>
            <option value="Kenyan">Kenyan</option>
            <option value="Malien">Malien</option>
          </optgroup>
          <optgroup label="Asie">
            <option value="Chinois">Chinois</option>
            <option value="Indien">Indien</option>
            <option value="Japonais">Japonais</option>
            <option value="Coréen">Coréen</option>
            <option value="Vietnamien">Vietnamien</option>
            <option value="Philippin">Philippin</option>
            <option value="Saoudien">Saoudien</option>
            <option value="Turc">Turc</option>
            <option value="Pakistanais">Pakistanais</option>
            <option value="Bangladais">Bangladais</option>
          </optgroup>
          <optgroup label="Amérique">
            <option value="Américain">Américain</option>
            <option value="Canadien">Canadien</option>
            <option value="Brésilien">Brésilien</option>
            <option value="Argentin">Argentin</option>
            <option value="Chilien">Chilien</option>
            <option value="Mexicain">Mexicain</option>
            <option value="Cubain">Cubain</option>
            <option value="Péruvien">Péruvien</option>
            <option value="Colombien">Colombien</option>
          </optgroup>
          <optgroup label="Océanie">
            <option value="Australien">Australien</option>
            <option value="Néo-Zélandais">Néo-Zélandais</option>
            <option value="Papouasien">Papouasien</option>
            <option value="Fidjien">Fidjien</option>
          </optgroup>
          <optgroup label="Reste du monde">
            <option value="Afghan">Afghan</option>
            <option value="Russe">Russe</option>
            <option value="Ukrainien">Ukrainien</option>
            <option value="Kazakh">Kazakh</option>
            <option value="Iranien">Iranien</option>
            <option value="Irakien">Irakien</option>
            <option value="Syrien">Syrien</option>
            <option value="Libanais">Libanais</option>
            <option value="Palestinien">Palestinien</option>
          </optgroup>
        </template>
    </FormSelect>
</template>
<script>
import FormSelect from '@/components/Form/Select';

    export default {
        name: "SelectCountry",
        components: {
            FormSelect
        },
        props: {
            value: {
                type: String,
                default: null
            },
            error: {
                type: Boolean,
                default: null
            },
            label: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: null
            }
        },
        data() {
            return {
                nationality: this.value
            }
        },
        watch: {
            value(newValue) {
                this.nationality = newValue;
            }
        },
        methods: {
            onSelect(value) {
                this.$emit('setValue', value);
            }
        }
    }
</script>