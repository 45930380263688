<template>
  <div class="eval-contact-card" :class="active ? 'active' : ''">
    <button class="btn ecc--close p-0" @click="$emit('remove', contact.id)" v-if="isEditable">
      <img src="@/assets/bouton-secondaire-close.svg" alt />
    </button>
    <div class="ecc--form" v-if="isEditMode">
        <div class="ecc--form-item">
            <img src="@/assets/icon-filaire-user.svg" alt="">
            <input v-model="data.lastName" type="text" class="form-control form-control-sm" placeholder="Nom *">
            <input v-model="data.firstName" type="text" class="form-control form-control-sm" placeholder="Prénom *">
        </div>
      <div v-if="!$v.data.lastName.required && $v.data.lastName.$error" class="text-left">
        <small class="text-danger">Le nom est obligatoire</small>
      </div>
      <div v-if="!$v.data.firstName.required && $v.data.firstName.$error" class="text-left">
        <small class="text-danger">Le prénom est obligatoire</small>
      </div>
        <div class="ecc--form-item">
            <img src="@/assets/icon-filaire-salon.svg" alt="">
            <input v-model="data.titre" type="text" class="form-control form-control-sm" placeholder="Titre">
        </div>
        <div class="ecc--form-item">
            <img src="@/assets/icon-filaire-home.svg" alt="">
            <input v-model="data.address" type="text" class="form-control form-control-sm" placeholder="Adresse">
        </div>
        <div class="ecc--form-item">
            <img src="@/assets/icon-filaire-bed.svg" alt="">
            <input v-model="data.postalCode" type="text" class="form-control form-control-sm" placeholder="Code postal">
            <input v-model="data.city" type="text" class="form-control form-control-sm" placeholder="Ville">
        </div>
        <div class="ecc--form-item">
            <img src="@/assets/icon-filaire-tel.svg" alt="">
            <input v-model="data.phone" type="text" class="form-control form-control-sm" placeholder="Numéro de téléphone fixe *">
        </div>
      <div v-if="$v.data.phone && $v.data.phone.$error" class="text-left">
        <small  class="text-danger">Le numéro de téléphone est obligatoire</small>
      </div>
        <div class="ecc--form-item-referent">
          <label :for="`referent--${contact.id}`" class="btn">Est référent</label>
          <input type="checkbox"
                 :id="`referent-${contact.id}`"
                 name="Est référent"
                 v-model="data.isReferent"/>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn ecc--edit p-1" @click.prevent="switchEditMode">
            <img src="@/assets/icon-plein-edit.svg" alt />
          </button>
        </div>
    </div>
    <div class="ecc--wrap" v-else>
      <div class="ecc--item">
        <img src="@/assets/icon-filaire-user.svg" alt />
        <h6>{{contact.name}} {{contact.lastname}}</h6>
      </div>
      <div class="ecc--item">
        <img src="@/assets/icon-filaire-salon.svg" alt="">
        <h6>{{contact.title}}</h6>
      </div>
      <div class="ecc--item">
        <img src="@/assets/icon-filaire-home.svg" alt />
        <h6>{{contact.address}}</h6>
      </div>
      <div class="ecc--item">
        <img src="@/assets/icon-filaire-bed.svg" alt />
        <h6>{{contact.codePostal}} {{contact.ville}}</h6>
      </div>
      <div class="ecc--item">
        <img src="@/assets/icon-filaire-tel.svg" alt />
        <h6>{{contact.telephone}}</h6>
      </div>
      <div class="ecc--item mb-0" v-if="contact.isResponsable">
        <p class="bold mb-0 referent">Référent</p>
      </div>
      <div class="d-flex justify-content-end" v-if="isEditable">
        <button class="btn ecc--edit p-1" @click.prevent="switchEditMode">
          <img src="@/assets/icon-plein-edit.svg" alt />
        </button>
      </div>
    </div>
  </div>
</template>
<script>

import {numeric, required} from "vuelidate/lib/validators";

export default {
  name: "EvalContactCard",
  props: {
    contact: {
      type: Object,
      default:()  => {}
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  validations() {
    return {
      data: {
        phone: {
          required,
          numeric
        },
        lastName: {
          required
        },
        firstName: {
          required
        }
      }
    };
  },
  data() {
    return {
      isEditMode: false,
      data: {
        firstName: '',
        lastName: '',
        titre: '',
        address: '',
        phone: '',
        postalCode: '',
        city: '',
        isReferent: false
      }
    };
  },
  watch: {
    contact(val) {
      this.data = val;
    }
  },
  mounted(){
    this.data = this.contact
  },
  methods: {
    switchEditMode() {
      if (this.$v.$invalid && this.isEditMode) {
        this.$v.$touch();
        return;
      }
      this.isEditMode = !this.isEditMode
      if (this.isEditMode) { return }
      this.$emit('update', {
        ...this.data,
        isReferent: this.data.isReferent ? this.data.isReferent : false
      })
    }
  }
};
</script>