<template>
  <div class="container pt-4">
    <h5 class="eval-item--title">Contexte de la demande</h5>
    <div class="eval-item--card cr--context">
      <div class="cr--context--part" :key="context.title">
        <div class="row" v-for="input in context.inputs" :key="input.id">
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>{{ input.label }}</p>
            <template v-if="input.defaultComment === 'Pas de commentaire'">
              <textarea class="form-control" v-model="input.response"></textarea>
            </template>
            <template v-else>
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <p class="font-weight-bold">Description du plan d'aide en cours</p>
      <div class="cr--context--part" :key="pec.title">
        <div class="row" v-for="input in pec.inputs" :key="input.id">
          <div v-if="input.id < 46 || input.id > 63" class="col-sm-12 col-md-8 cr_entry">
            <p>{{ input.label }}</p>
            <template v-if="input.defaultComment === 'Pas de commentaire'">
              <textarea class="form-control" v-model="input.response"></textarea>
            </template>
            <template v-else>
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <table class="table pec-table">
            <thead>
            <tr>
              <th>Type</th>
              <th>Mode</th>
              <th>H/Mois</th>
              <th>Taux Horaire</th>
              <th>Montant mensuel</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
            <tbody>
            <tr>
              <td>Heures semaine:</td>
              <td>
                <p>{{planAide.includes("Prestataire") ? "Prestataire" : null}}</p>
                <p>{{planAide.includes("Mandataire") ? "Mandataire" : null}}</p>
                <p>{{planAide.includes("Gré à gré") ? "Gré à Gré" : null}}</p>
              </td>
              <td>
                <p>{{planAide.includes("Prestataire") ? this.pec.inputs[9].response : null}}</p>
                <p>{{planAide.includes("Mandataire") ? this.pec.inputs[15].response : null}}</p>
                <p>{{planAide.includes("Gré à gré") ? this.pec.inputs[21].response : null}}</p>
              </td>
              <td>
                <p>{{planAide.includes("Prestataire") ? this.pec.inputs[10].response : null}}</p>
                <p>{{planAide.includes("Mandataire") ? this.pec.inputs[16].response : null}}</p>
                <p>{{planAide.includes("Gré à gré") ? this.pec.inputs[22].response : null}}</p>
              </td>
              <td>
                <p>{{planAide.includes("Prestataire") ? this.pec.inputs[11].response : null}}</p>
                <p>{{planAide.includes("Mandataire") ? this.pec.inputs[17].response : null}}</p>
                <p>{{planAide.includes("Gré à gré") ? this.pec.inputs[23].response : null}}</p>
              </td>
            </tr>

            <tr>
              <td>Heures dimanche/férié:</td>
              <td>
                <p>{{planAide.includes("Prestataire") ? "Prestataire" : null}}</p>
                <p>{{planAide.includes("Mandataire") ? "Mandataire" : null}}</p>
                <p>{{planAide.includes("Gré à gré") ? "Gré à Gré" : null}}</p>
              </td>
              <td>
                <p>{{planAide.includes("Prestataire") ? this.pec.inputs[12].response : null}}</p>
                <p>{{planAide.includes("Mandataire") ? this.pec.inputs[18].response : null}}</p>
                <p>{{planAide.includes("Gré à gré") ? this.pec.inputs[24].response : null}}</p>
              </td>
              <td>
                <p>{{planAide.includes("Prestataire") ? this.pec.inputs[13].response : null}}</p>
                <p>{{planAide.includes("Mandataire") ? this.pec.inputs[19].response : null}}</p>
                <p>{{planAide.includes("Gré à gré") ? this.pec.inputs[25].response : null}}</p>
              </td>
              <td>
                <p>{{planAide.includes("Prestataire") ? this.pec.inputs[14].response : null}}</p>
                <p>{{planAide.includes("Mandataire") ? this.pec.inputs[20].response : null}}</p>
                <p>{{planAide.includes("Gré à gré") ? this.pec.inputs[26].response : null}}</p>
              </td>
            </tr>

            <tr>
              <td>Total:</td>
              <td>
                <p>{{planAide.includes("Prestataire") ? "Prestataire" : null}}</p>
                <p>{{planAide.includes("Mandataire") ? "Mandataire" : null}}</p>
                <p>{{planAide.includes("Gré à gré") ? "Gré à Gré" : null}}</p>
              </td>
              <td>
                <p v-if="planAide.includes('Prestataire')">{{this.pec.inputs[9].response && this.pec.inputs[21].response ? parseInt(this.pec.inputs[9].response) + parseInt(this.pec.inputs[12].response) : DEFAULT_VALUE}}</p>
                <p v-if="planAide.includes('Mandataire')">{{this.pec.inputs[15].response && this.pec.inputs[18].response ? parseInt(this.pec.inputs[15].response) + parseInt(this.pec.inputs[18].response) : DEFAULT_VALUE}}</p>
                <p v-if="planAide.includes('Gré à gré')">{{this.pec.inputs[21].response && this.pec.inputs[24].response ? parseInt(this.pec.inputs[21].response) + parseInt(this.pec.inputs[24].response) : DEFAULT_VALUE}}</p>
              </td>
              <td>
                <p v-if="planAide.includes('Prestataire')">{{this.pec.inputs[10].response && this.pec.inputs[13].response ? parseInt(this.pec.inputs[10].response) + parseInt(this.pec.inputs[13].response) : DEFAULT_VALUE}}</p>
                <p v-if="planAide.includes('Mandataire')">{{this.pec.inputs[16].response && this.pec.inputs[19].response ? parseInt(this.pec.inputs[16].response) + parseInt(this.pec.inputs[19].response) : DEFAULT_VALUE}}</p>
                <p v-if="planAide.includes('Gré à gré')">{{this.pec.inputs[22].response && this.pec.inputs[25].response ? parseInt(this.pec.inputs[22].response) + parseInt(this.pec.inputs[25].response) : DEFAULT_VALUE}}</p>
              </td>
              <td>
                <p v-if="planAide.includes('Prestataire')">{{this.pec.inputs[11].response && this.pec.inputs[14].response ? parseInt(this.pec.inputs[11].response) + parseInt(this.pec.inputs[14].response) : DEFAULT_VALUE}}</p>
                <p v-if="planAide.includes('Mandataire')">{{this.pec.inputs[17].response && this.pec.inputs[20].response ? parseInt(this.pec.inputs[17].response) + parseInt(this.pec.inputs[20].response) : DEFAULT_VALUE}}</p>
                <p v-if="planAide.includes('Gré à gré')">{{this.pec.inputs[23].response && this.pec.inputs[26].response ? parseInt(this.pec.inputs[23].response) + parseInt(this.pec.inputs[26].response) : DEFAULT_VALUE}}</p>
              </td>
            </tr>

            <tr>
              <td colspan="5"></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CRComponent from "./CRComponent";
import {mapActions, mapState} from "vuex";
import moment from "moment/moment";

export default {
  name: "CR_Demand",
  extends: CRComponent,
  data() {
    return {
      DEFAULT_VALUE: '--',
      context: [],
      pec: [],
      planAide: ""
    };
  },
  mounted() {
    const payload = {
      page: {
        folder: "social",
        domains: ["context", "pec"],
        evaluationId: this.$route.params.evaluationId,
      }
    };
    this.loadData(payload);
  },
  methods: {
    ...mapActions("GenerateTemplate", ["getGenerateTemplate", "getGenerateAnswerTemplate"]),
    exportSynthesis() {
      const synthesis = {};
      const payload = {
        page: {
          folder: "social",
          domains: ["context", "pec"],
          evaluationId: this.$route.params.evaluationId,
        }
      };
      this.loadData(payload);

      this.context.inputs?.forEach(input => {
        synthesis[input.id] = input.response || 'Non renseigné';
      });

      this.pec.inputs?.forEach(input => {
        synthesis[input.id] = input.response || 'Non renseigné';
      });

      const HMoisTotal = [46, 52, 58, 49, 55, 61]
          .map(index => parseInt(synthesis[index]))
          .filter(value => !isNaN(value))
          .reduce((sum, value) => sum + value, 0);

      const tauxTotal = [47, 53, 59, 50, 56, 62]
          .map(index => parseInt(synthesis[index]))
          .filter(value => !isNaN(value))
          .reduce((sum, value) => sum + value, 0);

      const monthlyTotal = [48, 54, 60, 51, 57, 63]
          .map(index => parseInt(synthesis[index]))
          .filter(value => !isNaN(value))
          .reduce((sum, value) => sum + value, 0);

      return {
        demandDate: this.evaluation.demandDate ? this.evaluationDates.demandDate : this.DEFAULT_VALUE,
        evaluationDate: this.evaluation.evaluationDate ? this.evaluationDates.evaluationDate : this.DEFAULT_VALUE,
        demandNature: synthesis[1],
        ruptureEvent: synthesis[3],
        demandOrigin: synthesis[2],
        demandOriginComment: synthesis[65],
        otherDemand: synthesis[4],
        peoplePresent: synthesis[5],
        clientExpression: synthesis[9],
        tiersExpression: synthesis[7],
        PEC01: synthesis[37],
        PEC02: synthesis[38],
        PEC03: synthesis[39],
        PEC04: synthesis[40],
        PEC05: synthesis[41],
        PEC06: synthesis[42],
        PEC07: synthesis[43],
        demandComment: synthesis[72],
        planMode: synthesis[45],
        HMoisSemaine: this.getAmount(synthesis[46], synthesis[52], synthesis[58]),
        tauxSemaine: this.getAmount(synthesis[47], synthesis[53], synthesis[59]),
        monthlySemaine: this.getAmount(synthesis[48], synthesis[54], synthesis[60]),
        HMoisDF: this.getAmount(synthesis[49], synthesis[55], synthesis[61]),
        tauxDF: this.getAmount(synthesis[50], synthesis[56], synthesis[62]),
        monthlyDF: this.getAmount(synthesis[51], synthesis[57], synthesis[63]),
        HMoisTotal: HMoisTotal,
        tauxTotal: tauxTotal,
        monthlyTotal: monthlyTotal,
        participationUsager: synthesis[44],
        planNote: synthesis[64],
        pecComment: synthesis[79],
      }
    },
    getAmount(presta, manda, gre) {
      const none = "Non renseigné";

      return (presta === none ? "" : presta) + " ; " + (manda === none ? "" : manda) + " ; " + (gre === none ? "" : gre);
    },
    mapInputsWithResponses(inputs, responsesList) {
      return inputs.map(input => {
        const responses = responsesList?.filter(res => res.questionId === input.id);
        const responseValue = responses?.length > 0
            ? responses.map(res => res.responseValue).join(', ')
            : input.defaultComment;

        return {
          ...input,
          response: responseValue
        };
      });
    },

    async loadData(payload) {
      try {
        const data = await this.getGenerateTemplate({ payload });
        const responses = await this.getGenerateAnswerTemplate({ payload });

        if (Array.isArray(data)) {
          this.context = data.map(section => {
            return {
              ...section,
              inputs: this.mapInputsWithResponses(section.inputs, responses[0].responsesList)
            };
          })[0];

          this.pec = data.map(section => {
            return {
              ...section,
              inputs: this.mapInputsWithResponses(section.inputs, responses[1].responsesList)
            };
          })[1];

          this.planAide = this.pec.inputs[8].response;
        } else {
          console.error('data n\'est pas un tableau', data);
        }
      } catch (error) {
        console.error('Erreur lors du chargement des données :', error);
      }
    }
  },
  computed: {
    ...mapState("Evaluation", ["evaluation"]),
    evaluationDates() {
      return {
        demandDate: this.evaluation.demandDate ? moment(this.evaluation.demandDate).format("DD/MM/YYYY") : null,
        evaluationDate: this.evaluation.evaluationDate ? moment.utc(this.evaluation.evaluationDate).local(true).format("DD/MM/YYYY à HH:mm") : null
      }
    },
  }
};
</script>