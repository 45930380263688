import {client as ApolloClient} from '@/api/Apollo';
import { GET_CONTACT } from '@/api/GraphQLQueries/evaluation/contact/get-contact-query'
import { API_URL } from "../../../components/Util/EnvVariable";
import { get } from '@/api/Fetch';

export const getContact = ({ commit }, { id }) => {
    commit('setContactLoading');
    return ApolloClient.query({
        query: GET_CONTACT,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setContactSuccess', res.data.contact);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setContactError', err.response ? err.response.data : err);
        throw err;
    })
};

export const getAllContacts = async ({ commit }, { id }) => {
    try {
        const response = await get(`${API_URL}/contacts/${id}`);
        const data = await response.json();
        commit('setContacts', data)
    } catch (error) {
        console.error(error);
        commit('setContactError', error.response ? error.response.data : error);
        throw error;
    }
};

export default {
    getContact,
    getAllContacts
};
