import {get, postAsApplicationJson} from "../../../api/Fetch";
import {API_URL} from "../../../components/Util/EnvVariable";

export const getTerms = async ({ commit }, { lang }) =>  {
    try {
        const response = await get(`${API_URL}/get-terms/${lang}`);
        const data = await response.json();
        commit("setTerms", data);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getTermsAccepted = async ({ commit }, { version }) =>  {
    try {
        const response = await get(`${API_URL}/get-terms-accepted/${version}`);
        commit("setTermsAccepted" ,response.status);
        commit("setToggleModal", response.status)
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const postTermsAccepted = async ({ commit }, { version }) => {
    try {
        const response = await postAsApplicationJson(`${API_URL}/post-terms-accepted/${version}`);
        commit("setPostTerms");
        commit("setTermsAccepted" ,response.status);
        commit("setToggleModal", response.status);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export default {
    getTerms,
    getTermsAccepted,
    postTermsAccepted,
}