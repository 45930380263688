<template>
    <div class="evaluation">
      <loader :loader="loading"/>
        <transition name="fade" mode="out-in">
            <router-view/>
        </transition>

      <b-modal id="quit-evaluation"
               :no-close-on-backdrop="true"
               :centered="true" size="lg">
        <template v-slot:modal-title>
          Quitter l'évaluation
        </template>
        <p>Êtes-vous certain de vouloir quitter l'évaluation en cours ?</p>
        <template v-slot:modal-footer>
          <b-button class="mt-2" variant="btn col-3 btn-outline-secondary" @click="$bvModal.hide('quit-evaluation')">Retour</b-button>
          <b-button v-if="canAccessItem()" class="mt-2" variant="btn col-3 btn-outline-danger" @click="logoutEval">Déconnexion</b-button>
        </template>
      </b-modal>

    </div>
</template>
<script>
import {mapActions, mapMutations} from "vuex";
import {LOCAL_STORAGE_EVALUATION_ID} from "@/components/Util/EnvVariable";

export default {
      name: "EvaluationIndex",
      methods: {
        ...mapActions('Prestation', ['getPrestationList']),
        ...mapMutations('Evaluation', ['setInitEvaluation']),
        ...mapActions('Evaluation', ['getEvaluation', 'getGeneralQuestions']),
        ...mapActions("Act", ["getActList"]),
        ...mapActions("AggirQuestion", ["getAggirQuestionList"]),
        ...mapActions("User", ["logout", "logoutPSC"]),
        logoutEval(){
          this.logoutPSC();
          this.logout().then((res) => {
            window.sessionStorage.removeItem(LOCAL_STORAGE_EVALUATION_ID);
            this.$router.push({ name: "login" }).then(() => {
              window.location.reload();
            });
            return res;
          }).catch((err) => {
            window.sessionStorage.removeItem(LOCAL_STORAGE_EVALUATION_ID);
            this.$router.push({ name: "login" }).then(() => {
              window.location.reload();
            });
            throw err;
          });
        },
        canAccessItem() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_AUTH_LOGOUT');
        },
      },
      data: () => ({
        loading: true
      }),
      mounted() {
        this.getAggirQuestionList({limit: 0});
        this.setInitEvaluation();

        if(!this.$route.params.evaluationId){
          return;
        }

        this.loading = true;
        return Promise.all([
          this.getGeneralQuestions(),
          this.getEvaluation({id: this.$route.params.evaluationId}),
          this.getPrestationList({
            filters: [
              JSON.stringify({
                column: 'name',
                val: 'Actes'
              })
            ]
          })
        ]).then((data) => {
          this.loading = false;
          window.sessionStorage.setItem(LOCAL_STORAGE_EVALUATION_ID, this.$route.params.evaluationId);
          return data;
        }).catch((err) => {
          this.loading = false;
          this.$notify.error("Impossible d'accéder à cette évaluation");
          throw err;
        })
      }
    };
</script>
