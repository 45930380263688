var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar"},[_c('div',{staticClass:"nav__content"},[_c('img',{staticClass:"avatar",attrs:{"src":require("@/assets/avatar.png"),"width":"52"}}),_c('div',{staticClass:"user-info"},[_c('span',[_vm._v(_vm._s(_vm.getFirstname)+" "+_vm._s(_vm.getLastname))])]),(_vm.accessProSanteConnect && (!_vm.isloggedPSC || _vm.autoLogout))?_c('div',{staticClass:"wrapper"},[_vm._m(0)]):_vm._e(),(_vm.accessProSanteConnect)?_c('img',{staticClass:"cursor-pointer position-pointer",staticStyle:{"margin-left":"20px"},attrs:{"src":(_vm.isloggedPSC && !_vm.autoLogout)
      ? require('@/assets/pro_sante_connect.svg')
      : require('@/assets/not_connected_pro_sante_connect.svg')},on:{"click":function($event){$event.preventDefault();return _vm.openWindowPSC.apply(null, arguments)}}}):_vm._e()]),_c('img',{staticClass:"logo cursor-pointer",attrs:{"src":require("@/assets/logo.png")},on:{"click":function($event){$event.preventDefault();return _vm.gotToHome.apply(null, arguments)}}}),_c('div',{staticClass:"eval--header-btn-wrap"},[(_vm.accessRollback)?_c('a',{staticClass:"btn",on:{"click":function($event){$event.preventDefault();return _vm.gotToHome.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/bouton-primaire-home.svg")}})]):_vm._e(),(
        _vm.$route.name !== 'cr'
        && _vm.$route.name !== 'devis'
        && _vm.$route.name !== 'login'
        && _vm.$route.name !== 'loginPsc'
        && _vm.connectedUser
        && _vm.accessCr
        && (this.$route.path !== '/evaluation-accueil' && this.$route.path !== '/login' && this.$route.path !== '/evaluation-login')
      )?_c('router-link',{staticClass:"btn",attrs:{"to":{name:'cr'},"data-prefix":"2"}},[_c('img',{attrs:{"src":require("@/assets/bouton-primaire-cr.svg")}})]):_c('router-link',{staticClass:"btn",attrs:{"to":{name:'project-life'},"data-prefix":"2"}},[_c('img',{attrs:{"src":require("@/assets/bouton-primaire-q.svg")}})]),(_vm.accessBackoffice && (this.$route.path !== '/evaluation-accueil' && this.$route.path !== '/login' && this.$route.path !== '/evaluation-login'))?_c('router-link',{staticClass:"btn",attrs:{"to":{name:'client-list'},"data-prefix":"2"}},[_c('img',{attrs:{"src":require("@/assets/bouton-primaire-switch.svg")}})]):_vm._e(),(_vm.canAccessItem() && this.$route.path !== '/login' && this.$route.path !== '/evaluation-login')?_c('a',{staticClass:"btn",on:{"click":function($event){return _vm.logoutEval()}}},[_c('img',{attrs:{"src":require("@/assets/bouton-primaire-power-off.svg"),"width":"32"}})]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon infobulle"},[_c('span',{staticClass:"tooltip position-pointer"},[_vm._v("Vous êtes déconnectés de PRO SANTE CONNECT, veuillez vous reconnecter en cliquant sur PRO SANTE CONNECT")]),_c('span',[_c('img',{attrs:{"src":require("@/assets/warning_pro_sante_connect.svg")}})])])
}]

export { render, staticRenderFns }