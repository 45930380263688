export default {
    ruptureEvent: {
        r1: {
            item: "ruptureEvent-r1",
            active: true,
            problem: "Dégradation de l'état de santé",
            goal: "Travail en coordination avec le cercle de soin en vue d'améliorer ou maintenir l'état de santé.",
            actions: {
                choices: [
                    {value:'r1', label: "Informer Médecin et les autres professionnels de santé de notre intervention"},
                    {value:'r2', label: "Surveiller et alerter de toute modification de l'état de santé"},
                    {value:'r3', label: "Proposer une gestion de cas par une cellule interne"},
                    {value:'r4', label: "Proposer une gestion de cas par une MAIA"},
                    {value:'r5', label: "Contacter un réseau de santé"},
                    {value:'r6', label: "Contacter un SSIAD ou une IDEL"},
                    {value:'r7', label: "Aide psychologue extérieure"},
                    {value:'r8', label: "Aide psychologue interne"}
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        },
        r2: {
            item: "ruptureEvent-r2",
            active: true,
            problem: "Hospitalisation récente",
            goal: "Prévenir une nouvelle hospitalisation ",
            actions: {
                choices: [
                    {value:'r1', label: "Adapter la prise en charge après l'hospitalisation"},
                    {value:'r2', label: "Surveiller et alerter de toute modification de l'état de santé"},
                    {value:'r3', label: "Proposer une gestion de cas par une cellule interne"},
                    {value:'r4', label: "Proposer une gestion de cas par une MAIA"},
                    {value:'r5', label: "Contacter un réseau de santé"},
                    {value:'r6', label: "Contacter un SSIAD ou une IDEL"}
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        },
        r3: {
            item: "ruptureEvent-r3",
            active: true,
            problem: "Changement de lieu de vie",
            goal: "Permettre l'adaptation au nouveau lieu de vie",
            actions: {
                choices: [
                    {value:'r1', label: "Vérifier avec la personne, sa famille, l'adaptation du nouveau lieu de vie"},
                    {value:'r2', label: "Vérifier la permanence de la continuité des soins dans le nouveau lieu de vie"},
                    {value:'r3', label: "Proposer des promenades et reprérages du quartier"},
                    {value:'r4', label: "Proposer, mettre en œuvre les aides techniques nécessaires"},
                    {value:'r5', label: "Proposer l'intervention d'un ergothérapeute"},
                    {value:'r6', label: "Vérifier si la personne à besoin d'une aide administrative en relation avec le changement de lieu de vie"}
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        },
        r4: {
            item: "ruptureEvent-r4",
            active: true,
            problem: "Départ/Décès d'un proche",
            goal: "Permettre l'adaptation au changement de situation",
            actions: {
                choices: [
                    {value:'r1', label: "Etre attentif à l'apparition de troubles de l'humeur en relation avec l'évènement"},
                    {value:'r2', label: "Proposer un accompagnement psychologique en interne"},
                    {value:'r3', label: "Proposer une gestion de cas par une cellule interne"},
                    {value:'r4', label: "Proposer un accompagnement psychologique extérieur"},
                    {value:'r5', label: "Proposer une gestion de cas par une MAIA"}
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        },
        r5: {
            item: "ruptureEvent-r5",
            active: true,
            problem: "Perte d'autonomie ou maladie d'un proche",
            goal: "Favoriser l'écoute de la personne et adapter l'aide et l'environnement à la situation",
            actions: {
                choices: [
                    {value:'r1', label: "Etre attentif à l'apparition de troubles de l'humeur en relation avec l'évènement"},
                    {value:'r2', label: "Vérifier que la personne peut prendre des nouvelles de son proche"},
                    {value:'r3', label: "Proposer un accompagnement psychologique en interne"},
                    {value:'r4', label: "Proposer une gestion de cas par une cellule interne"},
                    {value:'r5', label: "Proposer un accompagnement psychologique extérieur"},
                    {value:'r6', label: "Proposer une gestion de cas par une MAIA"}
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        },
        r6: {
            item: "ruptureEvent-r6",
            active: true,
            problem: "Epuisement de l'aidant",
            goal: "Eviter, réduire d'épuisement de l'aidant",
            actions: {
                choices: [
                    {value:'r1', label: "Informer l'aidant sur les dispositifs de soutien aux aidants"},
                    {value:'r2', label: "Proposer des activités d'aide aux aidants"},
                    {value:'r3', label: "Proposer un accueil de jour ou toute autre forme de répit pour l'aidant"},
                    {value:'r4', label: "Proposer un accompagnement psychologique en interne"},
                    {value:'r5', label: "Proposer une gestion de cas par une cellule interne"},
                    {value:'r6', label: "Proposer un accompagnement psychologique extérieur"},
                    {value:'r7', label: "Proposer une gestion de cas par une MAIA"},
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        },
        r7: {
            item: "ruptureEvent-r7",
            active: true,
            problem: "Entrée du conjoint en établissement",
            goal: "Permettre l'adaptation au changement de situation",
            actions: {
                choices: [
                    {value:'r1', label: "Vérifier, organiser la possibilité de visiter le conjoint en établissement"},
                    {value:'r2', label: "Proposer, organiser des visios ou appels téléphoniques avec le proche en établissement"},
                    {value:'r3', label: "Aide psychologue interne"}
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        },
        r8: {
            item: "ruptureEvent-r8",
            active: true,
            problem: "Autre",
            goal: "",
            actions: {
                choices: [
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        },
        other: {
            item: "ruptureEvent-other",
            active: true,
            problem: "",
            goal: "",
            actions: "",
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        }
    },
    logementAdaptation: {
        logement: {
            r1: {
                title: "Adaptation du logement",
                item: "logementAdaptation-logement-r1",
                active: true,
                problem: "ACCES LOGEMENT: Marches",
                goal: "Permettre une circulation et une déambulation aisée et sans risques",
                actions: {
                    choices: [
                        {value:'r1', label: "Vérifier La possibilité pour la personne d'accéder et de sortir de son logement"},
                        {value:'r2', label: "Contacter le bailleur"},
                        {value:'r3', label: "Contacter un ergothérapeute"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r2: {
                item: "logementAdaptation-logement-r2",
                active: true,
                problem: "ACCES LOGEMENT: Escalier",
                goal: "Permettre une circulation et une déambulation aisée et sans risques",
                actions: {
                    choices: [
                        {value:'r1', label: "Proposer la pose d'une main courante le long de l'escalier"},
                        {value:'r2', label: "Vérifier La possibilité pour la personne d'accéder et de sortir de son logement"},
                        {value:'r3', label: "Contacter le bailleur"},
                        {value:'r4', label: "Contacter un ergothérapeute"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r6: {
                item: "logementAdaptation-logement-r6",
                active: true,
                problem: "ACCES LOGEMENT: Abords encombrés de feuilles ou autres végétaux glissants",
                goal: "Permettre une circulation et une déambulation aisée et sans risques",
                actions: {
                    choices: [
                        {value:'r1', label: "Proposer l'intervention d'un jardinier pour dégager les abords"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            }
        },
        bedroom: {
            r1: {
                title: "Acessibilité et problèmes liés à l'habitat dans la chambre 1",
                item: "logementAdaptation-bedroom-r1",
                active: true,
                problem: "ACCES CHAMBRE:\n\n Présence de marches,\n Sols glissants ou vétustes,\n Tapis ou mobilier génant les déplacements,\n Encombrement ou pièce trop exigue,\n Problème d'éclairage,\n Problème de chauffage,\n Humidité et moisissures,\n Problème de propreté,\n Fermeture des volets impossible.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20°,\n Maintenir un taux d'humidité compris entre 40% et 60%,\n Maintenir la pièce en état de propreté,\n Permettre l'ouverture et la fermeture facile et sans risque des volets",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envissager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r6', label: "Enlever les tapis"},
                        {value:'r7', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r8', label: "Proteger les angles vifs du mobilier"},
                        {value:'r9', label: "Proposer un grand ménage par hommes toutes mains"},
                        {value:'r10', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r11', label: "Actions de ménage du plan d'aide"},
                        {value:'r12', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r13', label: "Demander l'intervention d'un électricien"},
                        {value:'r14', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r15', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r16', label: "Régler/installer les radiateurs"},
                        {value:'r17', label: "Chauffage d'appoint"},
                        {value:'r18', label: "Aérer régulièrement"},
                        {value:'r19', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r20', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r21', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r22', label: "Actions de ménage du plan d'aide"},
                        {value:'r23', label: "Faire intervenir une société spécialisée"},
                        {value:'r24', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r2: {
                title: "Acessibilité et problèmes liés à l'habitat dans la chambre 2",
                item: "logementAdaptation-bedroom-r2",
                active: true,
                problem: "ACCES CHAMBRE:\n\n Présence de marches,\n Sols glissants ou vétustes,\n Tapis ou mobilier génant les déplacements,\n Encombrement ou pièce trop exigue,\n Problème d'éclairage,\n Problème de chauffage,\n Humidité et moisissures,\n Problème de propreté,\n Fermeture des volets impossible.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20°,\n Maintenir un taux d'humidité compris entre 40% et 60%,\n Maintenir la pièce en état de propreté,\n Permettre l'ouverture et la fermeture facile et sans risque des volets",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envissager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r6', label: "Enlever les tapis"},
                        {value:'r7', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r8', label: "Proteger les angles vifs du mobilier"},
                        {value:'r9', label: "Proposer un grand ménage par hommes toutes mains"},
                        {value:'r10', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r11', label: "Actions de ménage du plan d'aide"},
                        {value:'r12', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r13', label: "Demander l'intervention d'un électricien"},
                        {value:'r14', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r15', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r16', label: "Régler/installer les radiateurs"},
                        {value:'r17', label: "Chauffage d'appoint"},
                        {value:'r18', label: "Aérer régulièrement"},
                        {value:'r19', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r20', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r21', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r22', label: "Actions de ménage du plan d'aide"},
                        {value:'r23', label: "Faire intervenir une société spécialisée"},
                        {value:'r24', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r3: {
                title: "Acessibilité et problèmes liés à l'habitat dans la chambre 3",
                item: "logementAdaptation-bedroom-r3",
                active: true,
                problem: "ACCES CHAMBRE:\n\n Présence de marches,\n Sols glissants ou vétustes,\n Tapis ou mobilier génant les déplacements,\n Encombrement ou pièce trop exigue,\n Problème d'éclairage,\n Problème de chauffage,\n Humidité et moisissures,\n Problème de propreté,\n Fermeture des volets impossible.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20°,\n Maintenir un taux d'humidité compris entre 40% et 60%,\n Maintenir la pièce en état de propreté,\n Permettre l'ouverture et la fermeture facile et sans risque des volets",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envissager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r6', label: "Enlever les tapis"},
                        {value:'r7', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r8', label: "Proteger les angles vifs du mobilier"},
                        {value:'r9', label: "Proposer un grand ménage par hommes toutes mains"},
                        {value:'r10', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r11', label: "Actions de ménage du plan d'aide"},
                        {value:'r12', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r13', label: "Demander l'intervention d'un électricien"},
                        {value:'r14', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r15', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r16', label: "Régler/installer les radiateurs"},
                        {value:'r17', label: "Chauffage d'appoint"},
                        {value:'r18', label: "Aérer régulièrement"},
                        {value:'r19', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r20', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r21', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r22', label: "Actions de ménage du plan d'aide"},
                        {value:'r23', label: "Faire intervenir une société spécialisée"},
                        {value:'r24', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r4: {
                title: "Acessibilité et problèmes liés à l'habitat dans la chambre 4",
                item: "logementAdaptation-bedroom-r4",
                active: true,
                problem: "ACCES CHAMBRE:\n\n Présence de marches,\n Sols glissants ou vétustes,\n Tapis ou mobilier génant les déplacements,\n Encombrement ou pièce trop exigue,\n Problème d'éclairage,\n Problème de chauffage,\n Humidité et moisissures,\n Problème de propreté,\n Fermeture des volets impossible.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20°,\n Maintenir un taux d'humidité compris entre 40% et 60%,\n Maintenir la pièce en état de propreté,\n Permettre l'ouverture et la fermeture facile et sans risque des volets",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envissager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r6', label: "Enlever les tapis"},
                        {value:'r7', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r8', label: "Proteger les angles vifs du mobilier"},
                        {value:'r9', label: "Proposer un grand ménage par hommes toutes mains"},
                        {value:'r10', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r11', label: "Actions de ménage du plan d'aide"},
                        {value:'r12', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r13', label: "Demander l'intervention d'un électricien"},
                        {value:'r14', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r15', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r16', label: "Régler/installer les radiateurs"},
                        {value:'r17', label: "Chauffage d'appoint"},
                        {value:'r18', label: "Aérer régulièrement"},
                        {value:'r19', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r20', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r21', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r22', label: "Actions de ménage du plan d'aide"},
                        {value:'r23', label: "Faire intervenir une société spécialisée"},
                        {value:'r24', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r5: {
                title: "Acessibilité et problèmes liés à l'habitat dans la chambre 5",
                item: "logementAdaptation-bedroom-r5",
                active: true,
                problem: "ACCES CHAMBRE:\n\n Présence de marches,\n Sols glissants ou vétustes,\n Tapis ou mobilier génant les déplacements,\n Encombrement ou pièce trop exigue,\n Problème d'éclairage,\n Problème de chauffage,\n Humidité et moisissures,\n Problème de propreté,\n Fermeture des volets impossible.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20°,\n Maintenir un taux d'humidité compris entre 40% et 60%,\n Maintenir la pièce en état de propreté,\n Permettre l'ouverture et la fermeture facile et sans risque des volets",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envissager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r6', label: "Enlever les tapis"},
                        {value:'r7', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r8', label: "Proteger les angles vifs du mobilier"},
                        {value:'r9', label: "Proposer un grand ménage par hommes toutes mains"},
                        {value:'r10', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r11', label: "Actions de ménage du plan d'aide"},
                        {value:'r12', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r13', label: "Demander l'intervention d'un électricien"},
                        {value:'r14', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r15', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r16', label: "Régler/installer les radiateurs"},
                        {value:'r17', label: "Chauffage d'appoint"},
                        {value:'r18', label: "Aérer régulièrement"},
                        {value:'r19', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r20', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r21', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r22', label: "Actions de ménage du plan d'aide"},
                        {value:'r23', label: "Faire intervenir une société spécialisée"},
                        {value:'r24', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r6: {
                title: "Acessibilité et problèmes liés à l'habitat dans la chambre 6",
                item: "logementAdaptation-bedroom-r6",
                active: true,
                problem: "ACCES CHAMBRE:\n\n Présence de marches,\n Sols glissants ou vétustes,\n Tapis ou mobilier génant les déplacements,\n Encombrement ou pièce trop exigue,\n Problème d'éclairage,\n Problème de chauffage,\n Humidité et moisissures,\n Problème de propreté,\n Fermeture des volets impossible.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20°,\n Maintenir un taux d'humidité compris entre 40% et 60%,\n Maintenir la pièce en état de propreté,\n Permettre l'ouverture et la fermeture facile et sans risque des volets",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envissager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r6', label: "Enlever les tapis"},
                        {value:'r7', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r8', label: "Proteger les angles vifs du mobilier"},
                        {value:'r9', label: "Proposer un grand ménage par hommes toutes mains"},
                        {value:'r10', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r11', label: "Actions de ménage du plan d'aide"},
                        {value:'r12', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r13', label: "Demander l'intervention d'un électricien"},
                        {value:'r14', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r15', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r16', label: "Régler/installer les radiateurs"},
                        {value:'r17', label: "Chauffage d'appoint"},
                        {value:'r18', label: "Aérer régulièrement"},
                        {value:'r19', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r20', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r21', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r22', label: "Actions de ménage du plan d'aide"},
                        {value:'r23', label: "Faire intervenir une société spécialisée"},
                        {value:'r24', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r7: {
                title: "Acessibilité et problèmes liés à l'habitat dans la chambre 7",
                item: "logementAdaptation-bedroom-r7",
                active: true,
                problem: "ACCES CHAMBRE:\n\n Présence de marches,\n Sols glissants ou vétustes,\n Tapis ou mobilier génant les déplacements,\n Encombrement ou pièce trop exigue,\n Problème d'éclairage,\n Problème de chauffage,\n Humidité et moisissures,\n Problème de propreté,\n Fermeture des volets impossible.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20°,\n Maintenir un taux d'humidité compris entre 40% et 60%,\n Maintenir la pièce en état de propreté,\n Permettre l'ouverture et la fermeture facile et sans risque des volets",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envissager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r6', label: "Enlever les tapis"},
                        {value:'r7', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r8', label: "Proteger les angles vifs du mobilier"},
                        {value:'r9', label: "Proposer un grand ménage par hommes toutes mains"},
                        {value:'r10', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r11', label: "Actions de ménage du plan d'aide"},
                        {value:'r12', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r13', label: "Demander l'intervention d'un électricien"},
                        {value:'r14', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r15', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r16', label: "Régler/installer les radiateurs"},
                        {value:'r17', label: "Chauffage d'appoint"},
                        {value:'r18', label: "Aérer régulièrement"},
                        {value:'r19', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r20', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r21', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r22', label: "Actions de ménage du plan d'aide"},
                        {value:'r23', label: "Faire intervenir une société spécialisée"},
                        {value:'r24', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                },
                r8: {
                    title: "Acessibilité et problèmes liés à l'habitat dans la chambre 8",
                    item: "logementAdaptation-bedroom-r8",
                    active: true,
                    problem: "ACCES CHAMBRE:\n\n Présence de marches,\n Sols glissants ou vétustes,\n Tapis ou mobilier génant les déplacements,\n Encombrement ou pièce trop exigue,\n Problème d'éclairage,\n Problème de chauffage,\n Humidité et moisissures,\n Problème de propreté,\n Fermeture des volets impossible.",
                    goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20°,\n Maintenir un taux d'humidité compris entre 40% et 60%,\n Maintenir la pièce en état de propreté,\n Permettre l'ouverture et la fermeture facile et sans risque des volets",
                    actions: {
                        choices: [
                            {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                            {value:'r2', label: "Envisager la pose de rampe d'accès"},
                            {value:'r3', label: "Envissager l'installation de Monte escalier"},
                            {value:'r4', label: "Contacter un ergothérapeute"},
                            {value:'r5', label: "fixer les tapis par un ruban adhésif double face"},
                            {value:'r6', label: "Enlever les tapis"},
                            {value:'r7', label: "Oter ou deplacer le mobilier génant"},
                            {value:'r8', label: "Proteger les angles vifs du mobilier"},
                            {value:'r9', label: "Proposer un grand ménage par hommes toutes mains"},
                            {value:'r10', label: "Si syndrome de diogène fournir une aide psychologique"},
                            {value:'r11', label: "Actions de ménage du plan d'aide"},
                            {value:'r12', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                            {value:'r13', label: "Demander l'intervention d'un électricien"},
                            {value:'r14', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                            {value:'r15', label: "Proposer l'installation d'un chemin lumineux"},
                            {value:'r16', label: "Régler/installer les radiateurs"},
                            {value:'r17', label: "Chauffage d'appoint"},
                            {value:'r18', label: "Aérer régulièrement"},
                            {value:'r19', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                            {value:'r20', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                            {value:'r21', label: "Si syndrome de diogène fournir une aide psychologique"},
                            {value:'r22', label: "Actions de ménage du plan d'aide"},
                            {value:'r23', label: "Faire intervenir une société spécialisée"},
                            {value:'r24', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"}
                        ],
                        selected: []
                    },
                    affectation: {
                        choices: [
                            {value: 'c1', text: "Personne elle-même"},
                            {value: 'c2', text: "Conjoint/Famille"},
                            {value: 'c3', text: "Responsable secteur SAAD"},
                            {value: 'c4', text: "Aide à domicile"},
                            {value: 'c5', text: "Psychologue"},
                            {value: 'c6', text: "Travailleur social"},
                            {value: 'c7', text: "Tuteur/curateur"},
                            {value: 'c8', text: "Care Manager"}
                        ],
                        selected: []
                    }
                },
                r9: {
                    title: "Acessibilité et problèmes liés à l'habitat dans la chambre 9",
                    item: "logementAdaptation-bedroom-r9",
                    active: true,
                    problem: "ACCES CHAMBRE:\n\n Présence de marches,\n Sols glissants ou vétustes,\n Tapis ou mobilier génant les déplacements,\n Encombrement ou pièce trop exigue,\n Problème d'éclairage,\n Problème de chauffage,\n Humidité et moisissures,\n Problème de propreté,\n Fermeture des volets impossible.",
                    goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20°,\n Maintenir un taux d'humidité compris entre 40% et 60%,\n Maintenir la pièce en état de propreté,\n Permettre l'ouverture et la fermeture facile et sans risque des volets",
                    actions: {
                        choices: [
                            {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                            {value:'r2', label: "Envisager la pose de rampe d'accès"},
                            {value:'r3', label: "Envissager l'installation de Monte escalier"},
                            {value:'r4', label: "Contacter un ergothérapeute"},
                            {value:'r5', label: "fixer les tapis par un ruban adhésif double face"},
                            {value:'r6', label: "Enlever les tapis"},
                            {value:'r7', label: "Oter ou deplacer le mobilier génant"},
                            {value:'r8', label: "Proteger les angles vifs du mobilier"},
                            {value:'r9', label: "Proposer un grand ménage par hommes toutes mains"},
                            {value:'r10', label: "Si syndrome de diogène fournir une aide psychologique"},
                            {value:'r11', label: "Actions de ménage du plan d'aide"},
                            {value:'r12', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                            {value:'r13', label: "Demander l'intervention d'un électricien"},
                            {value:'r14', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                            {value:'r15', label: "Proposer l'installation d'un chemin lumineux"},
                            {value:'r16', label: "Régler/installer les radiateurs"},
                            {value:'r17', label: "Chauffage d'appoint"},
                            {value:'r18', label: "Aérer régulièrement"},
                            {value:'r19', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                            {value:'r20', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                            {value:'r21', label: "Si syndrome de diogène fournir une aide psychologique"},
                            {value:'r22', label: "Actions de ménage du plan d'aide"},
                            {value:'r23', label: "Faire intervenir une société spécialisée"},
                            {value:'r24', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"}
                        ],
                        selected: []
                    },
                    affectation: {
                        choices: [
                            {value: 'c1', text: "Personne elle-même"},
                            {value: 'c2', text: "Conjoint/Famille"},
                            {value: 'c3', text: "Responsable secteur SAAD"},
                            {value: 'c4', text: "Aide à domicile"},
                            {value: 'c5', text: "Psychologue"},
                            {value: 'c6', text: "Travailleur social"},
                            {value: 'c7', text: "Tuteur/curateur"},
                            {value: 'c8', text: "Care Manager"}
                        ],
                        selected: []
                    }
                }
            }
        },
        livingRoom: {
            r1: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : Salon 1",
                item: "logementAdaptation-livingRoom-r1",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION:\n\n Présence de marches,\n Sols glissants ou vétustes, \n Tapis ou mobilier génant les déplacements, \n Encombrement , ou pièce trop exigue, \n Problème d'éclairage, \n Problème de chauffage, \n Humidité et moisissures, \n Problème de propreté, \n Fermeture des volets impossible, \n Utilisation du gaz pour faire la cuisine",
                goal: "Permettre une circulation et une déambulation aisée et sans risques, \n Maintenir une température ambiante comprise entre 17° et 20°, \n Maintenir un taux d'humidité compris entre 40% et 60%, \n Maintenir la pièce en état de propreté, \n Permettre l'ouverture et la fermeture facile et sans risque des volets, \n Vivre dans un environnement sécurisé",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r2: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : Salon 2",
                item: "logementAdaptation-livingRoom-r2",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Sols glissants ou vétustes",
                goal: "Permettre une circulation et une déambulation aisée et sans risques",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r3: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : Salon 3",
                item: "logementAdaptation-livingRoom-r3",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Tapis ou mobilier génant les déplacements",
                goal: "Permettre une circulation et une déambulation aisée et sans risques",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r4: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : Salon 4",
                item: "logementAdaptation-livingRoom-r4",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Encombrement, ou pièce trop exigue",
                goal: "Permettre une circulation et une déambulation aisée et sans risques",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r5: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : Salon 5",
                item: "logementAdaptation-livingRoom-r5",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Problème d'éclairage",
                goal: "Permettre une circulation et une déambulation aisée et sans risques",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r6: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : Salon 6",
                item: "logementAdaptation-livingRoom-r6",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Humidité et moisissures",
                goal: "Maintenir un taux d'humidité compris entre 40% et 60%",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r7: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : Salon 7",
                item: "logementAdaptation-livingRoom-r7",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Problème de propreté",
                goal: "Maintenir la pièce en état de propreté",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r8: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : Salon 8",
                item: "logementAdaptation-livingRoom-r8",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Fermeture des volets impossible",
                goal: "Permettre l'ouverture et la fermeture facile et sans risque des volets",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r9: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : Salon 9",
                item: "logementAdaptation-livingRoom-r9",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Utilisation du gaz pour faire la cuisine",
                goal: "Vivre dans un environnement sécurisé",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
        },
        corridors: {
            r1: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : couloirs 1",
                item: "logementAdaptation-corridors-r1",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION:\n\n Présence de marches,\n Sols glissants ou vétustes, \n Tapis ou mobilier génant les déplacements, \n Encombrement , ou pièce trop exigue, \n Problème d'éclairage, \n Problème de chauffage, \n Humidité et moisissures, \n Problème de propreté, \n Fermeture des volets impossible, \n Utilisation du gaz pour faire la cuisine",
                goal: "Permettre une circulation et une déambulation aisée et sans risques, \n Maintenir une température ambiante comprise entre 17° et 20°, \n Maintenir un taux d'humidité compris entre 40% et 60%, \n Maintenir la pièce en état de propreté, \n Permettre l'ouverture et la fermeture facile et sans risque des volets, \n Vivre dans un environnement sécurisé",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r2: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : couloirs 2",
                item: "logementAdaptation-corridors-r2",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Sols glissants ou vétustes",
                goal: "Permettre une circulation et une déambulation aisée et sans risques",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r3: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : couloirs 3",
                item: "logementAdaptation-corridors-r3",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Tapis ou mobilier génant les déplacements",
                goal: "Permettre une circulation et une déambulation aisée et sans risques",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r4: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : couloirs 4",
                item: "logementAdaptation-corridors-r4",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Encombrement, ou pièce trop exigue",
                goal: "Permettre une circulation et une déambulation aisée et sans risques",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r5: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : couloirs 5",
                item: "logementAdaptation-corridors-r5",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Problème d'éclairage",
                goal: "Permettre une circulation et une déambulation aisée et sans risques",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r6: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : couloirs 6",
                item: "logementAdaptation-corridors-r6",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Humidité et moisissures",
                goal: "Maintenir un taux d'humidité compris entre 40% et 60%",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r7: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : couloirs 7",
                item: "logementAdaptation-corridors-r7",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Problème de propreté",
                goal: "Maintenir la pièce en état de propreté",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r8: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : couloirs 8",
                item: "logementAdaptation-corridors-r8",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Fermeture des volets impossible",
                goal: "Permettre l'ouverture et la fermeture facile et sans risque des volets",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r9: {
                title: "Acessibilité et problèmes liés à l'habitat dans les autres pièces de vie et les espaces de circulation : couloirs 9",
                item: "logementAdaptation-corridors-r9",
                active: true,
                problem: "AUTRES PIECES ET CIRCULATION: Utilisation du gaz pour faire la cuisine",
                goal: "Vivre dans un environnement sécurisé",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou deplacer le mobilier génant"},
                        {value:'r10', label: "Proteger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Chauffage d'appoint"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humdité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r24', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r25', label: "Actions de ménage du plan d'aide"},
                        {value:'r26', label: "Proposer le passage d'un homme toutes mains"},
                        {value:'r27', label: "Faire intervenir une société spécialisée"},
                        {value:'r28', label: "Signaler le dysfonctionnement au bailleur ou au propriétaire"},
                        {value:'r29', label: "Proposer un detecteur de gaz de ville"},
                        {value:'r30', label: "Remplacer la cusinière au gaz par de l'électrique"},
                        {value:'r31', label: "Installer un detecteur de fumée"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
        },
        bathroom: {
            r1: {
                title: "Eléménts posant des difficultés dans la salle de bain : Salle de bain 1",
                item: "logementAdaptation-bathroom-r1",
                active: true,
                problem: "ACCES SDB:\n\nPrésence de marches dans la pièce ou la douche,\n Présence de marches dans la pièce ou la douche, \n Sols glissants ou vétustes, \nTapis ou mobilier génant les déplacements,\n Encombrement des pièces,\n Problème electriques et/ou d'éclairage,\n Problème d'eau et/ou de chauffage, \n Humidité et moisissures, \n Problème de propreté, \n Absence de barres d'appui ou rampe, \n Absence de siège adapté dans la douche ou la baignoire.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20° et avoir une eau disponible a bonne température, \n Maintenir un taux d'humidité compris entre 40% et 60%, \n Maintenir la pièce en état de propreté, \n Sécuriser le temps de la toilette.",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "Fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou déplacer le mobilier génant"},
                        {value:'r10', label: "Protéger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un détecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Vérifier le système de contrôle de la température de l'eau"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer l'installation de barres d'appui"},
                        {value:'r24', label: "Proposer l'installation de siège adapté"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r2: {
                title: "Eléménts posant des difficultés dans la salle de bain : Salle de bain 2",
                item: "logementAdaptation-bathroom-r2",
                active: true,
                problem: "ACCES SDB:\n\nPrésence de marches dans la pièce ou la douche,\n Présence de marches dans la pièce ou la douche, \n Sols glissants ou vétustes, \nTapis ou mobilier génant les déplacements,\n Encombrement des pièces,\n Problème electriques et/ou d'éclairage,\n Problème d'eau et/ou de chauffage, \n Humidité et moisissures, \n Problème de propreté, \n Absence de barres d'appui ou rampe, \n Absence de siège adapté dans la douche ou la baignoire.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20° et avoir une eau disponible a bonne température, \n Maintenir un taux d'humidité compris entre 40% et 60%, \n Maintenir la pièce en état de propreté, \n Sécuriser le temps de la toilette.",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "Fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou déplacer le mobilier génant"},
                        {value:'r10', label: "Protéger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un détecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Vérifier le système de contrôle de la température de l'eau"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer l'installation de barres d'appui"},
                        {value:'r24', label: "Proposer l'installation de siège adapté"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r3: {
                title: "Eléménts posant des difficultés dans la salle de bain : Salle de bain 3",
                item: "logementAdaptation-bathroom-r3",
                active: true,
                problem: "ACCES SDB:\n\nPrésence de marches dans la pièce ou la douche,\n Présence de marches dans la pièce ou la douche, \n Sols glissants ou vétustes, \nTapis ou mobilier génant les déplacements,\n Encombrement des pièces,\n Problème electriques et/ou d'éclairage,\n Problème d'eau et/ou de chauffage, \n Humidité et moisissures, \n Problème de propreté, \n Absence de barres d'appui ou rampe, \n Absence de siège adapté dans la douche ou la baignoire.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20° et avoir une eau disponible a bonne température, \n Maintenir un taux d'humidité compris entre 40% et 60%, \n Maintenir la pièce en état de propreté, \n Sécuriser le temps de la toilette.",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "Fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou déplacer le mobilier génant"},
                        {value:'r10', label: "Protéger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un détecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Vérifier le système de contrôle de la température de l'eau"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer l'installation de barres d'appui"},
                        {value:'r24', label: "Proposer l'installation de siège adapté"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r4: {
                title: "Eléménts posant des difficultés dans la salle de bain : Salle de bain 4",
                item: "logementAdaptation-bathroom-r4",
                active: true,
                problem: "ACCES SDB:\n\nPrésence de marches dans la pièce ou la douche,\n Présence de marches dans la pièce ou la douche, \n Sols glissants ou vétustes, \nTapis ou mobilier génant les déplacements,\n Encombrement des pièces,\n Problème electriques et/ou d'éclairage,\n Problème d'eau et/ou de chauffage, \n Humidité et moisissures, \n Problème de propreté, \n Absence de barres d'appui ou rampe, \n Absence de siège adapté dans la douche ou la baignoire.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20° et avoir une eau disponible a bonne température, \n Maintenir un taux d'humidité compris entre 40% et 60%, \n Maintenir la pièce en état de propreté, \n Sécuriser le temps de la toilette.",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "Fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou déplacer le mobilier génant"},
                        {value:'r10', label: "Protéger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un détecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Vérifier le système de contrôle de la température de l'eau"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer l'installation de barres d'appui"},
                        {value:'r24', label: "Proposer l'installation de siège adapté"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r5: {
                title: "Eléménts posant des difficultés dans la salle de bain : Salle de bain 5",
                item: "logementAdaptation-bathroom-r5",
                active: true,
                problem: "ACCES SDB:\n\nPrésence de marches dans la pièce ou la douche,\n Présence de marches dans la pièce ou la douche, \n Sols glissants ou vétustes, \nTapis ou mobilier génant les déplacements,\n Encombrement des pièces,\n Problème electriques et/ou d'éclairage,\n Problème d'eau et/ou de chauffage, \n Humidité et moisissures, \n Problème de propreté, \n Absence de barres d'appui ou rampe, \n Absence de siège adapté dans la douche ou la baignoire.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20° et avoir une eau disponible a bonne température, \n Maintenir un taux d'humidité compris entre 40% et 60%, \n Maintenir la pièce en état de propreté, \n Sécuriser le temps de la toilette.",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "Fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou déplacer le mobilier génant"},
                        {value:'r10', label: "Protéger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un détecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Vérifier le système de contrôle de la température de l'eau"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer l'installation de barres d'appui"},
                        {value:'r24', label: "Proposer l'installation de siège adapté"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r6: {
                title: "Eléménts posant des difficultés dans la salle de bain : Salle de bain 6",
                item: "logementAdaptation-bathroom-r6",
                active: true,
                problem: "ACCES SDB:\n\nPrésence de marches dans la pièce ou la douche,\n Présence de marches dans la pièce ou la douche, \n Sols glissants ou vétustes, \nTapis ou mobilier génant les déplacements,\n Encombrement des pièces,\n Problème electriques et/ou d'éclairage,\n Problème d'eau et/ou de chauffage, \n Humidité et moisissures, \n Problème de propreté, \n Absence de barres d'appui ou rampe, \n Absence de siège adapté dans la douche ou la baignoire.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20° et avoir une eau disponible a bonne température, \n Maintenir un taux d'humidité compris entre 40% et 60%, \n Maintenir la pièce en état de propreté, \n Sécuriser le temps de la toilette.",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "Fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou déplacer le mobilier génant"},
                        {value:'r10', label: "Protéger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un détecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Vérifier le système de contrôle de la température de l'eau"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer l'installation de barres d'appui"},
                        {value:'r24', label: "Proposer l'installation de siège adapté"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r7: {
                title: "Eléménts posant des difficultés dans la salle de bain : Salle de bain 7",
                item: "logementAdaptation-bathroom-r7",
                active: true,
                problem: "ACCES SDB:\n\nPrésence de marches dans la pièce ou la douche,\n Présence de marches dans la pièce ou la douche, \n Sols glissants ou vétustes, \nTapis ou mobilier génant les déplacements,\n Encombrement des pièces,\n Problème electriques et/ou d'éclairage,\n Problème d'eau et/ou de chauffage, \n Humidité et moisissures, \n Problème de propreté, \n Absence de barres d'appui ou rampe, \n Absence de siège adapté dans la douche ou la baignoire.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20° et avoir une eau disponible a bonne température, \n Maintenir un taux d'humidité compris entre 40% et 60%, \n Maintenir la pièce en état de propreté, \n Sécuriser le temps de la toilette.",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "Fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou déplacer le mobilier génant"},
                        {value:'r10', label: "Protéger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un détecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Vérifier le système de contrôle de la température de l'eau"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer l'installation de barres d'appui"},
                        {value:'r24', label: "Proposer l'installation de siège adapté"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Responsable secteur SAAD"},
                        {value: 'c2', text: "Aide à domicile SAAD"},
                        {value: 'c3', text: "Psychologue SAAD"},
                        {value: 'c4', text: "IDEC SSIAD"},
                        {value: 'c5', text: "Infirmière SSIAD"},
                        {value: 'c6', text: "Care Manager"},
                    ],
                    selected: []
                }
            },
            r8: {
                title: "Eléménts posant des difficultés dans la salle de bain : Salle de bain 8",
                item: "logementAdaptation-bathroom-r8",
                active: true,
                problem: "ACCES SDB:\n\nPrésence de marches dans la pièce ou la douche,\n Présence de marches dans la pièce ou la douche, \n Sols glissants ou vétustes, \nTapis ou mobilier génant les déplacements,\n Encombrement des pièces,\n Problème electriques et/ou d'éclairage,\n Problème d'eau et/ou de chauffage, \n Humidité et moisissures, \n Problème de propreté, \n Absence de barres d'appui ou rampe, \n Absence de siège adapté dans la douche ou la baignoire.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20° et avoir une eau disponible a bonne température, \n Maintenir un taux d'humidité compris entre 40% et 60%, \n Maintenir la pièce en état de propreté, \n Sécuriser le temps de la toilette.",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "Fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou déplacer le mobilier génant"},
                        {value:'r10', label: "Protéger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un détecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Vérifier le système de contrôle de la température de l'eau"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer l'installation de barres d'appui"},
                        {value:'r24', label: "Proposer l'installation de siège adapté"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Responsable secteur SAAD"},
                        {value: 'c2', text: "Aide à domicile SAAD"},
                        {value: 'c3', text: "Psychologue SAAD"},
                        {value: 'c4', text: "IDEC SSIAD"},
                        {value: 'c5', text: "Infirmière SSIAD"},
                        {value: 'c6', text: "Care Manager"},
                    ],
                    selected: []
                }
            },
            r9: {
                title: "Eléménts posant des difficultés dans la salle de bain : Salle de bain 9",
                item: "logementAdaptation-bathroom-r9",
                active: true,
                problem: "ACCES SDB:\n\nPrésence de marches dans la pièce ou la douche,\n Présence de marches dans la pièce ou la douche, \n Sols glissants ou vétustes, \nTapis ou mobilier génant les déplacements,\n Encombrement des pièces,\n Problème electriques et/ou d'éclairage,\n Problème d'eau et/ou de chauffage, \n Humidité et moisissures, \n Problème de propreté, \n Absence de barres d'appui ou rampe, \n Absence de siège adapté dans la douche ou la baignoire.",
                goal: "Permettre une circulation et une déambulation aisée et sans risques,\n Maintenir une température ambiante comprise entre 17° et 20° et avoir une eau disponible a bonne température, \n Maintenir un taux d'humidité compris entre 40% et 60%, \n Maintenir la pièce en état de propreté, \n Sécuriser le temps de la toilette.",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Envisager l'installation de Monte escalier"},
                        {value:'r4', label: "Contacter un ergothérapeute"},
                        {value:'r5', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r6', label: "Proposer de changer le revêtement de sol"},
                        {value:'r7', label: "Fixer les tapis par un ruban adhésif double face"},
                        {value:'r8', label: "Enlever les tapis"},
                        {value:'r9', label: "Oter ou déplacer le mobilier génant"},
                        {value:'r10', label: "Protéger les angles vifs du mobilier"},
                        {value:'r11', label: "Proposer un grand ménage par homme toutes mains"},
                        {value:'r12', label: "Si syndrome de diogène fournir une aide psychologique"},
                        {value:'r13', label: "Actions de ménage du plan d'aide"},
                        {value:'r14', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r15', label: "Demander l'intervention d'un électricien"},
                        {value:'r16', label: "Faire installer un détecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r17', label: "Proposer l'installation d'un chemin lumineux"},
                        {value:'r18', label: "Régler/installer les radiateurs"},
                        {value:'r19', label: "Vérifier le système de contrôle de la température de l'eau"},
                        {value:'r20', label: "Aérer régulièrement"},
                        {value:'r21', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r22', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r23', label: "Proposer l'installation de barres d'appui"},
                        {value:'r24', label: "Proposer l'installation de siège adapté"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Responsable secteur SAAD"},
                        {value: 'c2', text: "Aide à domicile SAAD"},
                        {value: 'c3', text: "Psychologue SAAD"},
                        {value: 'c4', text: "IDEC SSIAD"},
                        {value: 'c5', text: "Infirmière SSIAD"},
                        {value: 'c6', text: "Care Manager"},
                    ],
                    selected: []
                }
            }
        },
        toilet: {
            r1: {
                title: "Eléménts posant des difficultés dans les WC 1",
                item: "logementAdaptation-toilet-r1",
                active: true,
                problem: "ACCES WC:\n\nPrésence de marches, \n Sols glissants ou vétustes, \n Ouverture et taille Inadapté a PMR, \nFonctionnement des WC défecteux, \nProblème electriques et/ou d'éclairage, \n Problème d'arrivée ou d'évacuation d'eau, \n Humidité et moisissures, \n Problème de propreté, \n Absence de barres d'appui ou rampe, \n Absence de Réhausse WC.",
                goal: "Permettre un accès aisé et sécurisé aux WC, \n Rétablir le bon fonctionnement des WC, permettre l'usage, \n Permettre un éclairage suffisant  et des interrupteurs accessibles, \n Assurer un bon fonctionnement de l'arrivée d'eau et de l'évacuation des WC,\n Maintenir un taux d'humidité compris entre 40% et 60%,\n Maintenir la pièce en état de propreté, \n Sécuriser l'installation et le relevage des WC, \n Garantir une bonne assise, faciliter l'instalation et le relevage.",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r4', label: "Proposer de changer le revêtement de sol"},
                        {value:'r5', label: "Contacter un ergothérapeute"},
                        {value:'r6', label: "Ouverture de porte à étalgir"},
                        {value:'r7', label: "WC non modifiables, non utilisables nécessitant l'achat d'un fauteuil \"garde-robe\""},
                        {value:'r8', label: "Envisager un fauteuil roulant ou démabulateur de largeur adaptée"},
                        {value:'r9', label: "Proposer l'intervention d'un plombier"},
                        {value:'r10', label: "Proposer l'intervention d'un homme toutes mains"},
                        {value:'r11', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r12', label: "Demander l'intervention d'un électricien"},
                        {value:'r13', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r14', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r15', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r16', label: "Proposer un nettoyage par homme toutes mains"},
                        {value:'r17', label: "Actions de ménage du plan d'aide"},
                        {value:'r18', label: "Proposer l'installation de barres d'appui"},
                        {value:'r19', label: "Contacter un ergothérapeute"},
                        {value:'r20', label: "Proposer l'installation d'un réhausse WC"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r2: {
                title: "Eléménts posant des difficultés dans les WC 2",
                item: "logementAdaptation-toilet-r2",
                active: true,
                problem: "ACCES WC: Sols glissants ou vétustes",
                goal: "Permettre un accès aisé et sécurisé aux WC ",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r4', label: "Proposer de changer le revêtement de sol"},
                        {value:'r5', label: "Contacter un ergothérapeute"},
                        {value:'r6', label: "Ouverture de porte à étalgir"},
                        {value:'r7', label: "WC non modifiables, non utilisables nécessitant l'achat d'un fauteuil \"garde-robe\""},
                        {value:'r8', label: "Envisager un fauteuil roulant ou démabulateur de largeur adaptée"},
                        {value:'r9', label: "Proposer l'intervention d'un plombier"},
                        {value:'r10', label: "Proposer l'intervention d'un homme toutes mains"},
                        {value:'r11', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r12', label: "Demander l'intervention d'un électricien"},
                        {value:'r13', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r14', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r15', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r16', label: "Proposer un nettoyage par homme toutes mains"},
                        {value:'r17', label: "Actions de ménage du plan d'aide"},
                        {value:'r18', label: "Proposer l'installation de barres d'appui"},
                        {value:'r19', label: "Contacter un ergothérapeute"},
                        {value:'r20', label: "Proposer l'installation d'un réhausse WC"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r3: {
                title: "Eléménts posant des difficultés dans les WC 3",
                item: "logementAdaptation-toilet-r3",
                active: true,
                problem: "ACCES WC: Ouverture et taille Inadapté a PMR",
                goal: "Permettre un accès aisé et sécurisé aux WC ",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r4', label: "Proposer de changer le revêtement de sol"},
                        {value:'r5', label: "Contacter un ergothérapeute"},
                        {value:'r6', label: "Ouverture de porte à étalgir"},
                        {value:'r7', label: "WC non modifiables, non utilisables nécessitant l'achat d'un fauteuil \"garde-robe\""},
                        {value:'r8', label: "Envisager un fauteuil roulant ou démabulateur de largeur adaptée"},
                        {value:'r9', label: "Proposer l'intervention d'un plombier"},
                        {value:'r10', label: "Proposer l'intervention d'un homme toutes mains"},
                        {value:'r11', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r12', label: "Demander l'intervention d'un électricien"},
                        {value:'r13', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r14', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r15', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r16', label: "Proposer un nettoyage par homme toutes mains"},
                        {value:'r17', label: "Actions de ménage du plan d'aide"},
                        {value:'r18', label: "Proposer l'installation de barres d'appui"},
                        {value:'r19', label: "Contacter un ergothérapeute"},
                        {value:'r20', label: "Proposer l'installation d'un réhausse WC"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r4: {
                title: "Eléménts posant des difficultés dans les WC 4",
                item: "logementAdaptation-toilet-r4",
                active: true,
                problem: "ACCES WC: Fonctionnement des WC défecteux",
                goal: "Rétablir le bon fonctionnement des WC, permettre l'usage.",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r4', label: "Proposer de changer le revêtement de sol"},
                        {value:'r5', label: "Contacter un ergothérapeute"},
                        {value:'r6', label: "Ouverture de porte à étalgir"},
                        {value:'r7', label: "WC non modifiables, non utilisables nécessitant l'achat d'un fauteuil \"garde-robe\""},
                        {value:'r8', label: "Envisager un fauteuil roulant ou démabulateur de largeur adaptée"},
                        {value:'r9', label: "Proposer l'intervention d'un plombier"},
                        {value:'r10', label: "Proposer l'intervention d'un homme toutes mains"},
                        {value:'r11', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r12', label: "Demander l'intervention d'un électricien"},
                        {value:'r13', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r14', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r15', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r16', label: "Proposer un nettoyage par homme toutes mains"},
                        {value:'r17', label: "Actions de ménage du plan d'aide"},
                        {value:'r18', label: "Proposer l'installation de barres d'appui"},
                        {value:'r19', label: "Contacter un ergothérapeute"},
                        {value:'r20', label: "Proposer l'installation d'un réhausse WC"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r5: {
                title: "Eléménts posant des difficultés dans les WC 5",
                item: "logementAdaptation-toilet-r5",
                active: true,
                problem: "ACCES WC: Problème electriques et/ou d'éclairage",
                goal: "Permettre un éclairage suffisant  et des interrupteurs accessibles",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r4', label: "Proposer de changer le revêtement de sol"},
                        {value:'r5', label: "Contacter un ergothérapeute"},
                        {value:'r6', label: "Ouverture de porte à étalgir"},
                        {value:'r7', label: "WC non modifiables, non utilisables nécessitant l'achat d'un fauteuil \"garde-robe\""},
                        {value:'r8', label: "Envisager un fauteuil roulant ou démabulateur de largeur adaptée"},
                        {value:'r9', label: "Proposer l'intervention d'un plombier"},
                        {value:'r10', label: "Proposer l'intervention d'un homme toutes mains"},
                        {value:'r11', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r12', label: "Demander l'intervention d'un électricien"},
                        {value:'r13', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r14', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r15', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r16', label: "Proposer un nettoyage par homme toutes mains"},
                        {value:'r17', label: "Actions de ménage du plan d'aide"},
                        {value:'r18', label: "Proposer l'installation de barres d'appui"},
                        {value:'r19', label: "Contacter un ergothérapeute"},
                        {value:'r20', label: "Proposer l'installation d'un réhausse WC"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r6: {
                title: "Eléménts posant des difficultés dans les WC 6",
                item: "logementAdaptation-toilet-r6",
                active: true,
                problem: "ACCES WC: Problème d'arrivée ou d'évacuation d'eau",
                goal: "Assurer un bon fonctionnement de l'arrivée d'eau et de l'évacuation des WC.",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r4', label: "Proposer de changer le revêtement de sol"},
                        {value:'r5', label: "Contacter un ergothérapeute"},
                        {value:'r6', label: "Ouverture de porte à étalgir"},
                        {value:'r7', label: "WC non modifiables, non utilisables nécessitant l'achat d'un fauteuil \"garde-robe\""},
                        {value:'r8', label: "Envisager un fauteuil roulant ou démabulateur de largeur adaptée"},
                        {value:'r9', label: "Proposer l'intervention d'un plombier"},
                        {value:'r10', label: "Proposer l'intervention d'un homme toutes mains"},
                        {value:'r11', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r12', label: "Demander l'intervention d'un électricien"},
                        {value:'r13', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r14', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r15', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r16', label: "Proposer un nettoyage par homme toutes mains"},
                        {value:'r17', label: "Actions de ménage du plan d'aide"},
                        {value:'r18', label: "Proposer l'installation de barres d'appui"},
                        {value:'r19', label: "Contacter un ergothérapeute"},
                        {value:'r20', label: "Proposer l'installation d'un réhausse WC"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r7: {
                title: "Eléménts posant des difficultés dans les WC 7",
                item: "logementAdaptation-toilet-r7",
                active: true,
                problem: "ACCES WC: Humidité et moisissures",
                goal: "Maintenir un taux d'humidité compris entre 40% et 60%",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r4', label: "Proposer de changer le revêtement de sol"},
                        {value:'r5', label: "Contacter un ergothérapeute"},
                        {value:'r6', label: "Ouverture de porte à étalgir"},
                        {value:'r7', label: "WC non modifiables, non utilisables nécessitant l'achat d'un fauteuil \"garde-robe\""},
                        {value:'r8', label: "Envisager un fauteuil roulant ou démabulateur de largeur adaptée"},
                        {value:'r9', label: "Proposer l'intervention d'un plombier"},
                        {value:'r10', label: "Proposer l'intervention d'un homme toutes mains"},
                        {value:'r11', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r12', label: "Demander l'intervention d'un électricien"},
                        {value:'r13', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r14', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r15', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r16', label: "Proposer un nettoyage par homme toutes mains"},
                        {value:'r17', label: "Actions de ménage du plan d'aide"},
                        {value:'r18', label: "Proposer l'installation de barres d'appui"},
                        {value:'r19', label: "Contacter un ergothérapeute"},
                        {value:'r20', label: "Proposer l'installation d'un réhausse WC"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r8: {
                title: "Eléménts posant des difficultés dans les WC 8",
                item: "logementAdaptation-toilet-r8",
                active: true,
                problem: "ACCES WC: Problème de propreté",
                goal: "Maintenir la pièce en état de propreté",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r4', label: "Proposer de changer le revêtement de sol"},
                        {value:'r5', label: "Contacter un ergothérapeute"},
                        {value:'r6', label: "Ouverture de porte à étalgir"},
                        {value:'r7', label: "WC non modifiables, non utilisables nécessitant l'achat d'un fauteuil \"garde-robe\""},
                        {value:'r8', label: "Envisager un fauteuil roulant ou démabulateur de largeur adaptée"},
                        {value:'r9', label: "Proposer l'intervention d'un plombier"},
                        {value:'r10', label: "Proposer l'intervention d'un homme toutes mains"},
                        {value:'r11', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r12', label: "Demander l'intervention d'un électricien"},
                        {value:'r13', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r14', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r15', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r16', label: "Proposer un nettoyage par homme toutes mains"},
                        {value:'r17', label: "Actions de ménage du plan d'aide"},
                        {value:'r18', label: "Proposer l'installation de barres d'appui"},
                        {value:'r19', label: "Contacter un ergothérapeute"},
                        {value:'r20', label: "Proposer l'installation d'un réhausse WC"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            r9: {
                title: "Eléménts posant des difficultés dans les WC 9",
                item: "logementAdaptation-toilet-r9",
                active: true,
                problem: "ACCES WC: Absence de barres d'appui ou rampe ",
                goal: "Sécuriser l'installation et le relevage des WC.",
                actions: {
                    choices: [
                        {value:'r1', label: "Envisager la pose de bornes antidérapantes sur les marches"},
                        {value:'r2', label: "Envisager la pose de rampe d'accès"},
                        {value:'r3', label: "Proposer la pose de tapis antidérapant"},
                        {value:'r4', label: "Proposer de changer le revêtement de sol"},
                        {value:'r5', label: "Contacter un ergothérapeute"},
                        {value:'r6', label: "Ouverture de porte à étalgir"},
                        {value:'r7', label: "WC non modifiables, non utilisables nécessitant l'achat d'un fauteuil \"garde-robe\""},
                        {value:'r8', label: "Envisager un fauteuil roulant ou démabulateur de largeur adaptée"},
                        {value:'r9', label: "Proposer l'intervention d'un plombier"},
                        {value:'r10', label: "Proposer l'intervention d'un homme toutes mains"},
                        {value:'r11', label: "Changer les ampoules si defecteuses ou augmenter la puissance"},
                        {value:'r12', label: "Demander l'intervention d'un électricien"},
                        {value:'r13', label: "Faire installer un detecteur de mouvement pour automatiser l'éclairage"},
                        {value:'r14', label: "Faire mesurer le taux d'humidité avec un hygromètre"},
                        {value:'r15', label: "Aérer régulièrement la pièce si humidité élevée ou installer un déshumidificateur"},
                        {value:'r16', label: "Proposer un nettoyage par homme toutes mains"},
                        {value:'r17', label: "Actions de ménage du plan d'aide"},
                        {value:'r18', label: "Proposer l'installation de barres d'appui"},
                        {value:'r19', label: "Contacter un ergothérapeute"},
                        {value:'r20', label: "Proposer l'installation d'un réhausse WC"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"},
                    ],
                    selected: []
                }
            }
        }
    },
    technicalHelp: [],
    technicalDefault: {
        default: {
            item: "technicalHelp-default",
            active: true,
            type: "noMaterials",
            problem: "",
            goal: "Il n'a pas été repéré d'aide technique à prévoir.",
            actions: "Vérifier dans la durée de la prise en charge si des aides techniques deviennent nécessaires.",
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        }
    },
    protectionMeasure: {
        r1: {
            item: "protectionMeasure-r1",
            active: true,
            problem: "Mesures à proposer",
            goal: "Que la personne soit informée et puisse faire le choix sur les dispositifs de protection dont elle pourrait bénéficier.",
            actions: {
                choices: [
                    {value: 'r1', label: "Mesure accompagnement social ou judiciaire"},
                    {value: 'r2', label: "Informer sur le Mandat de protection future"},
                    {value: 'r3', label: "Informer sur l'habilitation familiale"},
                    {value: 'r4', label: "Informer sur la sauvegarde de justice"},
                    {value: 'r5', label: "Informer sur la curatelle simple"},
                    {value: 'r6', label: "Informer sur la curatelle renfocée"},
                    {value: 'r7', label: "Informer sur la tutelle"},
                    {value: 'r8', label: "Informer sur les directives anticipées"},
                    {value: 'r9', label: "Informer sur la désignation d'une personne de confiance"}
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        },
        other: {
            item: "protectionMeasure-other",
            active: true,
            problem: "",
            goal: "Travailler en liaison avec les personnes/organismes désignés",
            actions: {
                choices: [
                    {value: 'r1', label: "Vérifier la disponibilité des coordonnées des personnes.organismes désignés"},
                    {value: 'r2', label: "Prendre contact avec les personnes / organismes désignés"}
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        }
    },
    pec: {
        r4: {
            item: "pec-r4",
            active: true,
            problem: "Faire une demande de plan d'aide ",
            goal: "Mettre en place un plan d'aide adapté aux besoins de la personne",
            actions: {
                choices: [
                    {value: 'r1', label: "APA"},
                    {value: 'r2', label: "Aide sociale"},
                    {value: 'r3', label: "PCH"},
                    {value: 'r4', label: "Caisse de retraite"},
                    {value: 'r5', label: "CPAM"},
                    {value: 'r6', label: "Assurance dépendance"}
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        },
        other: {
            item: "pec-other",
            active: true,
            problem: "",
            goal: "Adapter le plan d'aide actuel aux besoins de la personne",
            actions: {
                choices: [
                    {value: 'r1', label: "Demander une révision du GIR"},
                    {value: 'r2', label: "Demander une augmentation du plan d'aide"},
                    {value: 'r3', label: "Changer de mode d'intervention"},
                    {value: 'r4', label: "Demander un transfert du plan d'aide dans le département"}
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        }
    },
    socialInteraction: {
        eps11: {
            item: "socialInteraction-eps11",
            active: true,
            problem: "Sentiment ressenti  de solitude et/ou d'isolement",
            goal: "Permettre la mise en place des activités sociales souhaitées par la personne",
            actions: {
                choices: [
                    {value: 'r1', label: "Orienter la personne en interne"},
                    {value: 'r2', label: "Orienter la personne vers un dispositif externe"},
                    {value: 'r3', label: "Orienter la personne vers un CLIC"}
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        },
        eps06: {
            item: "socialInteraction-eps06",
            active: true,
            problem: "Précautions vis-à-vis de l'animal de compagnie",
            goal: "Garantir la sécurité des intervenants et celle de l'animal de compagnie",
            actions:  {
                choices: [],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        }
    },
    entourage: {
        default: {
            item: "entourage-default",
            active: true,
            problem: "Aidants",
            goal: "Soutenir les aidants, lutter contre l'épuisement ",
            actions: {
                choices: [
                    {value: 'r1', label: "Communiquer le numéro national pour les proches aidants de personnes handicapées pour se renseigner sur leurs droits et solutions 0 800 360 360"},
                    {value: 'r2', label: "Communiquer le numéro national pour les proches aidants de personnes âgées pour se renseigner sur leurs droits et solutions 0 800 10 39 39 "},
                    {value: 'r3', label: "Informer les aidants sur le dispositif de répit"},
                    {value: 'r4', label: "Informer les aidants sur le dispositif d'accueil de jour"},
                    {value: 'r5', label: "Proposer un soutien psychologique en interne"},
                    {value: 'r6', label: "Orienter l'aidant vers un CLIC ou espace autonomie"}
                ],
                selected: []
            },
            affectation: {
                choices: [
                    {value: 'c1', text: "Personne elle-même"},
                    {value: 'c2', text: "Conjoint/Famille"},
                    {value: 'c3', text: "Responsable secteur SAAD"},
                    {value: 'c4', text: "Aide à domicile"},
                    {value: 'c5', text: "Psychologue"},
                    {value: 'c6', text: "Travailleur social"},
                    {value: 'c7', text: "Tuteur/curateur"},
                    {value: 'c8', text: "Care Manager"},
                ],
                selected: []
            }
        }
    },
    weakness: {
        nutrition: {
            general: {
                item: "weakness-nutrition-general",
                active: true,
                actions:{
                    choices: [
                        {value: 'r1', label: "Orienter vers le médecin traitant"},
                        {value: 'r2', label: "Orienter vers un suivi diététique"},
                        {value: 'r3', label: "Peser régulièrement la personne"},
                        {value: 'r4', label: "Noter les troubles de la digestion et du transit"},
                        {value: 'r5', label: "Vérifier que le dentier, la texture des aliments présentés sont adaptés"},
                        {value: 'r6', label: "Vérifier que les repas sont bien pris"},
                        {value: 'r7', label: "Veiller à une alimentation équilibrée et agréable aux gouts de la personne"},
                        {value: 'r8', label: "Mettre en place le portage de repas"},
                        {value: 'r9', label: "Communiquer aux intervenant(es) une fiche sur le(s) régime(s) suivi(s)"},
                        {value: 'r10', label: "Communiquer aux intervenant(es) une fiche technique sur le(s) aliments(s) proscrits(s)"},
                        {value: 'r11', label: "Signaler les allergies au service de portage de repas "},
                        {value: 'r12', label: "Vérifier dans la liste des courses et dans le réfrigérateur l'absences d'aliments en rapport avec l'allergie"},
                        {value: 'r13', label: "Communiquer aux intervenant(es) une fiche technique sur les troubles de mastication ou de déglutition"},
                        {value: 'r14', label: "Alerter le médecin pour une exploration approfondie de la bouche et de l'œsophage"},
                        {value: 'r15', label: "Prendre RDV chez le dentiste"},
                        {value: 'r16', label: "Vérifier l'adaptation du dentier si la personne en porte."},
                        {value: 'r17', label: "Vérifier l'hygiène bucco-dentaire"},
                        {value: 'r18', label: "Proposer de l'eau géléifiée"},
                        {value: 'r19', label: "Communiquer aux intervenant(es) une fiche technique sur l'alimentation entérale"},
                        {value: 'r20', label: "Contacter le médecin traitant "},
                        {value: 'r21', label: "Proposer une éducation infirmière et passage quotidien d'une infirmière"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            imc: {
                active: true,
                problem: null,
                goal: "IMC compris entre 18,5 et 25"
            },
            mna: {
                active: true,
                problem: null,
                goal: "Aider à la prise en charge des problèmes nutritionnels"
            },
            icope: {
                active: true,
                problem: null,
                goal: "Aider à la prise en charge des problèmes nutritionnels"
            },
            regime: {
                active: true,
                problem: null,
                goal: "Aider au respect du régime"
            },
            allergy: {
                active: true,
                problem: null,
                goal: "Veiller a l'éviction des aliments contenant des ingrédients auxquels la personne est allergique "
            },
            eating: {
                active: true,
                problem: null,
                goal: "Eviter les fausses routes et favoriser une bonne digestion des aliments"
            },
            sonde: {
                active: true,
                problem: null,
                goal: "Aider à l'alimentation entérale"
            }
        },
        sense: {
            general: {
                item: "weakness-sense-general",
                active: true,
                actions: {
                    choices: [
                        {value: 'r22', label: "Prendre RDV chez un ophtalmologue"},
                        {value: 'r23', label: "Contacter le médecin traitant"},
                        {value: 'r24', label: "Veiller à un éclairage suffisant des lieux de vie"},
                        {value: 'r25', label: "Proposer une loupe de lecture"},
                        {value: 'r26', label: "Prendre RDV chez un ORL"},
                        {value: 'r27', label: "Faire vérifier l'appareillage auditif s'il existe"},
                        {value: 'r28', label: "Si le médecin n'est pas au courant, Contacter le médecin traitant"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            icopeSight: {
                active: true,
                problem: null,
                goal: "Prendre en charge la déficience visuelle et ses conséquences"
            },
            earing: {
                active: true,
                problem: null,
                goal: "Prendre en charge la déficience auditive et ses conséquences"
            },
            icopeEaring: {
                active: true,
                problem: null,
                goal: "Prendre en charge la déficience auditive et ses conséquences"
            },
            sensibility: {
                active: true,
                problem: null,
                goal: "Eviter les douleurs et les conséquences des troubles de la sensibilité"
            }
        },
        breathing: {
            general: {
                item: "weakness-breathing-general",
                active: true,
                actions: {
                    choices: [
                        {value: 'r29', label: "Communiquer aux intervenant(es) une fiche technique sur les troubles de la respiration"},
                        {value: 'r30', label: "Vérifier que le médecin à connaissance du problème"},
                        {value: 'r31', label: "Surveiller la respiration et alerter le médecin en cas de doute"},
                        {value: 'r32', label: "Communiquer aux intervenant(es) une fiche sur la conduite a tenir dans l'oxygnéothérapie"},
                        {value: 'r33', label: "S'assurer qu'une personne qualifiée et formée est en mesure de surveiller le débit, le fonctionnement de l'appareil et la position de la sonde"},
                        {value: 'r34', label: "Communiquer aux intervenant(es) une fiche technique sur les oedèmes des membres inférieurs"},
                        {value: 'r35', label: "Respect du régime  prescrit."},
                        {value: 'r36', label: "Pose de bas de contention si ordonnés par le médecin"},
                        {value: 'r37', label: "Relever les pieds du lit par des plots ou si lit médicalisé"},
                        {value: 'r38', label: "Signaler immédiatement au médecin l'apparition de douleur, rougeur, chaleur, plaies, suintements, difficultés respiratoires"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            breathingDifficulties: {
                active: true,
                problem: null,
                goal: "Aider la personne dans ses difficultés respiratoires"
            },
            oxygen: {
                active: true,
                problem: null,
                goal: "Aider la personne dans ses difficultés respiratoires"
            },
            member: {
                active: true,
                problem: null,
                goal: "Aider la personne à limiter, réduire, ses problèmes circulatoires des membres inférieurs"
            }
        },
        elimination: {
            general: {
                item: "weakness-elimination-general",
                active: true,
                actions: {
                    choices: [
                        {value: 'r39', label: "Communiquer aux intervenant(es) une fiche technique sur les troubles de l'élimination urinaire"},
                        {value: 'r40', label: "Surveiller les troubles d'éliminations urinaires et prévenir le médecin en cas de modification "},
                        {value: 'r41', label: "Communiquer aux intervenant(es) une fiche technique sur les troubles de l'élimination fécale"},
                        {value: 'r42', label: "Surveiller les troubles d'eliminitations fécales et prévenir le médecin en cas de modification "},
                        {value: 'r43', label: "Communiquer aux intervenant(es) une fiche technique sur les stomies"},
                        {value: 'r44', label: "Surveiller les troubles de la stomie et prévenir le médecin en cas de modification "},
                        {value: 'r45', label: "Communiquer aux intervenant(es) une fiche technique sur les sondes vésicales et étuis péniens"},
                        {value: 'r46', label: "Surveiller les troubles et prévenir le médecin en cas de modification "},
                        {value: 'r47', label: "Communiquer aux intervenant(es) une fiche technique sur les protections hygiéniques"},
                        {value: 'r48', label: "Etablir un plan de changement des protections hygiéniques"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            urineDifficulties: {
                active: true,
                problem: null,
                goal: "Aider à la prise en charge des problèmes urinaires"
            },
            transitTroubles: {
                active: true,
                problem: null,
                goal: "Aider la personne à la prise en charge des problèmes digestifs"
            },
            stomia: {
                active: true,
                problem: null,
                goal: "Aider la personne et les professionnels dans la gestion de la stomie"
            },
            sonde: {
                active: true,
                problem: null,
                goal: "Aider la personne et les professionnels dans la gestion du dispositif urinaire"
            },
            protection: {
                active: true,
                problem: null,
                goal: "Maintenir l'hygiène intime de la personne"
            }
        },
        motor: {
            general: {
                item: "weakness-motor-general",
                active: true,
                problem: null,
                goal: "Permettre à la personne de maintenir son autonomie en sécurité",
                actions: {
                    choices: [
                        {value: 'r49', label: "Communiquer aux intervenant(es) une fiche technique sur les conduites à tenir face aux déficits moteur"},
                        {value: 'r50', label: "Communiquer aux intervenant(es) une fiche technique sur l'accompagnement des troubles de la mobilité"},
                        {value: 'r51', label: "Communiquer aux intervenant(es) une fiche technique sur les conduites à tenir face aux déficits moteur"},
                        {value: 'r52', label: "Communiquer aux intervenant(es) une fiche technique sur les conduites à tenir face aux déficits moteur"},
                        {value: 'r53', label: "Surveiller les troubles et prévenir le médecin "},
                        {value: 'r54', label: "Proposer un atelier d'activité physique adaptée ou de prévention des chutes"},
                        {value: 'r55', label: "Proposer une activité physqiue adaptée ou de prévention des chutes au domicile"},
                        {value: 'r56', label: "Proposer un atelier de TAI CHI / QI QONG"},
                        {value: 'r57', label: "Proposer une activité de TAI CHI / QI QONG au domicile"},
                        {value: 'r58', label: "Proposer un dispositif portatif de protection airbag"},
                        {value: 'r59', label: "Proposer une consultation d'ergothérapie"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                },
            }
        },
        skin: {
            general: {
                item: "weakness-skin-general",
                active: true,
                actions: {
                    choices: [
                        {value: 'r60', label: "Communiquer aux intervenant(es) une fiche technique sur l'accompagnement des personnes a risque d'escarre(s)"},
                        {value: 'r61', label: "Surveiller l'état cutané et prévenir le médecin de toute anomalie"},
                        {value: 'r62', label: "Vérifier la disponibilité et l'adéquation du matériel de prévention des escarre (matelas, coussins, …)"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            norton: {
                active: true,
                problem: null,
                goal: "Prévenir le risque d'escarre(s)"
            },
            other: {
                active: true,
                problem: null,
                goal: "Maintenir une peau saine"
            }
        },
        pain: {
            pain: {
                item: "weakness-pain-pain",
                active: true,
                problem: null,
                goal: "Eviter les douleurs ",
                actions: {
                    choices: [
                        // ici
                        {value: 'r1', label: "Surveiller et noter les douleurs et prévenir le médecin ou l'infirmère"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            }
        },
        brain: {
            miniCog: {
                item: "weakness-brain-miniCog",
                active: true,
                problem: null,
                goal: "Ralentir, accompagner les troubles mnésiques et cognitifs, et les troubles de l'humeur",
                actions: {
                    choices: [
                        {value: 'r63', label: "Communiquer aux intervenant(es) une fiche technique sur l'accompagnement des personnes présentant des troubles cognitifs, amnésiques, dépressifs."},
                        {value: 'r64', label: "Proposer un atelier de stimulation cognitive et mnésique"},
                        {value: 'r65', label: "Proposer une action de stimulation cognitive et mnésique au domicile"},
                        {value: 'r66', label: "Proposer une prise en charge psychologique"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
        },
        treatment: {
            general:{
                item: "weakness-nutrition-general",
                active: true,
                actions: {
                    choices: [
                        {value: 'r67', label: "Proposer l'utilisation d'un semainier"},
                        {value: 'r68', label: "Encourager la personne à prendre un médecin traitant"}
                    ],
                    selected: []
                },
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"},
                        {value: 'c8', text: "Care Manager"}
                    ],
                    selected: []
                }
            },
            drugs: {
                active: true,
                problem: null,
                goal: "Permettre une bonne observance et éviter les risques iatrogéniques"
            },
            doctor: {
                active: true,
                problem: null,
                goal: "Avoir un médecin traitant déclaré"
            }
        }
    },
    risks: [],
    contact: {
        selected: {
            item: "contact-selected",
            problem: "",
            goal: "",
            actions: "",
            affectation: ""
        }
    }
}