import Moment from 'moment';
import {DEVIS_STATUS} from "@/store/Modules/Evaluation/state";
import moment from "moment/moment";

export default {
    setEvaluationProperty(state, property) {
        if(!state.evaluation[property.name]){
            state.evaluation[property.name] = "";
        }
        state.evaluation[property.name] = property.value;
    },
    setEvaluationAnswerProperty(state, property) {
        const answer = state.evaluation.answerList.find((answer) => answer.question.id === property.questionId);
        if(!answer) {
            const newAnswer = {
                answerStch: property.answerStch,
                id: property.id,
                question: {
                    id: property.questionId
                }
            }
            state.evaluation.answerList.push(newAnswer)
            state.aggirAnswer = newAnswer;
            return;
        }
        const ind = state.evaluation.answerList.findIndex(ans => ans.question.id === property.questionId);
        state.evaluation.answerList[ind].answerStch = property.answerStch;
        state.aggirAnswer = state.evaluation.answerList[ind];
    },
    setTreatmentProperty(state, property) {
        let id = 'general';
        if(property.name.indexOf('sos') !== -1){
            id = 'sense'
        }
        if(property.name.indexOf('sn') !== -1){
            id = 'nutrition'
        }
        if(property.name.indexOf('src') !== -1){
            id = 'breathing'
        }
        if(property.name.indexOf('sfe') !== -1){
            id = 'elimination'
        }
        if(property.name.indexOf('sfm') !== -1){
            id = 'motor'
        }
        if(property.name.indexOf('spt') !== -1){
            id = 'skin'
        }
        if(property.name.indexOf('sd') !== -1){
            id = 'pain'
        }
        if(property.name.indexOf('smd') !== -1){
            id = 'brain'
        }
        if(property.name.indexOf('stsm') !== -1 || property.name.indexOf('STSM') !== -1){
            id = 'treatment'
        }
        if(!state[id][property.name]){
            state[id][property.name] = {
                questionName: property.name,
                questionType: "question",
                response: null
            };
        }
        state[id][property.name].response = property.value;
    },
    setSyntheseGoalProperty(state, property) {
        const itemsParts = property.item.split('-');
        const category = itemsParts[0];
        const isMaterialRelated = (category === "technicalHelp");
        const isRisksRelated = (category === "risks");
        const name = itemsParts[itemsParts.length - 1];
        let goalsActions = state[category];
        let hasSubItem = null;
        if(itemsParts.length === 3) {
            goalsActions = goalsActions[itemsParts[1]][name];
            hasSubItem = itemsParts[1];
        } else if(!isMaterialRelated && !isRisksRelated) {
            goalsActions = goalsActions[name];
        } else if(isMaterialRelated){
            goalsActions = {
                item: property.item,

                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"}
                    ],
                    selected: []
                }
            };
        } else if (isRisksRelated) {
            goalsActions = {
                item: property.item,
                affectation: {
                    choices: [
                        {value: 'c1', text: "Personne elle-même"},
                        {value: 'c2', text: "Conjoint/Famille"},
                        {value: 'c3', text: "Responsable secteur SAAD"},
                        {value: 'c4', text: "Aide à domicile"},
                        {value: 'c5', text: "Psychologue"},
                        {value: 'c6', text: "Travailleur social"},
                        {value: 'c7', text: "Tuteur/curateur"}
                    ],
                    selected: []
                }
            };
        }
        if(goalsActions) {
            const newVal = {
                ...goalsActions,
                problem: property.problem,
                goal: property.goal,
                actions: property.actions,
                affectation: property.affectation,
                active: property.active
            }

            if(hasSubItem) {
                state[category][hasSubItem][name] = newVal;
                return;
            } else if (!isMaterialRelated && !isRisksRelated) {
                state[category][name] = newVal;
                return;
            }
            const elementIndex = state[category].findIndex((el) => el.item === property.item);
            if(elementIndex !== -1) {
                state[category][elementIndex] = newVal;
            } else {
                state[category].push(newVal);
            }
        }
    },
    setEvaluationLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setEvaluationActLoading(state) {
        state.evalActsLoading = true;
        state.error = null;
    },
    setEvaluationSaveLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setAggirAnswerLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setEvaluationList(state, evaluations) {
        state.evaluations = evaluations
        state.loading = false;
        state.error = null;
    },
    setTotalEvaluationList(state, total) {
        state.total = total;
    },
    setEvaluationSuccess(state, evaluation) {
        const lastContrat = evaluation.contracts && evaluation.contracts.length > 0 ? evaluation.contracts.sort((a, b) => {
            if(Moment(a.createdAt).isBefore(b.createdAt)){
                return 1;
            }else{
                return -1;
            }
        })[0] : null;
        const contract = {
                ...lastContrat,
                id: lastContrat ? lastContrat.id : null,
                status: lastContrat ? lastContrat.status : DEVIS_STATUS,
                aivqDetails: lastContrat ? JSON.parse(lastContrat.aivqDetails) : [],
                avqDetails: lastContrat ? JSON.parse(lastContrat.avqDetails) : [],
                totalAIVQMinutes: lastContrat ? lastContrat.totalAIVQMinutes : 0.0,
                totalAIVQCost: lastContrat ? lastContrat.totalAIVQCost : 0.0,
                totalAVQMinutes: lastContrat ? lastContrat.totalAVQMinutes : 0.0,
                totalAVQCost: lastContrat ? lastContrat.totalAVQCost : 0.0,
                displayPeriod: lastContrat && lastContrat.displayPeriod && lastContrat.displayPeriod !== "" ? lastContrat.displayPeriod : "sem",
                pecDetails: lastContrat ?  JSON.parse(lastContrat.pecDetails) : {
                    description: "Prestataire",
                    type: "Heures semaine",
                    value: "",
                    DFdescription: "Prestataire",
                    DFtype: "Heures dimanche / férié",
                    DFvalue: ""
                }
            }

        const seanceDetails = state.defautSeanceOrder;
        state.evaluation = {
            ...evaluation,
            structureId: evaluation.structure ? evaluation.structure.id : null,
            structureName: evaluation.structure ? evaluation.structure.name : null,
            agencyId: evaluation.agency ? evaluation.agency.id : null,
            materials_ids: evaluation.materials ? evaluation.materials.reduce((acc, obj) => { return [...acc, obj.material ? obj.material.id : []]}, []) : [],
            acts: evaluation.acts ? evaluation.acts : [],
            evaluationHasPrestationList: evaluation.carePlanPrestations ? evaluation.carePlanPrestations : [],
            evaluationHasActList: evaluation.acts ? evaluation.acts : [],
            clientId: evaluation.client ? evaluation.client.id : null,
            evaluateById: evaluation.evaluateBy ? evaluation.evaluateBy.id : null,
            additionalInfos: evaluation.additionalInfos ? evaluation.additionalInfos : '',
            evaluatorComments: evaluation.evaluatorComments ? JSON.parse(evaluation.evaluatorComments) :  {
                demandComment: "",
                pecComment: "",
                habitatComment: "",
                entourageComment: "",
                autonomyComment: "",
                healthComment: "",
                clientComment: "",
                tiersComment: ""
            },
            contract: contract,
            habits: evaluation.habits ? evaluation.habits : [],
            seanceDetails: evaluation.seanceDetails ? JSON.parse(evaluation.seanceDetails) : seanceDetails,
            heuresSemaine: evaluation.heuresSemaine,
            heuresDimanche: evaluation.heuresDimanche
        };

        state.loading = false;
        state.error = null;
    },
    setDeleteRiskSuccess(state, evaluation) {
        state.evaluation = {
            ...state.evaluation,
            risks: evaluation.risks
        }
        state.loading = false;
        state.error = null;
    },
    setEvaluationActSuccess(state, evaluationActList) {
        state.evaluation = {
            ...state.evaluation,
            evaluationHasActList: evaluationActList.items ? evaluationActList.items : []
        }
        state.loading = false;
        state.error = null;
    },
    setEvaluationPrestationSuccess(state, evaluationPrestationList) {
        state.evaluation = {
            ...state.evaluation,
            evaluationHasPrestationList: evaluationPrestationList.items ? evaluationPrestationList.items : []
        }
        state.loading = false;
        state.error = null;
    },
    setPostActSuccess(state, act) {
        state.loading = false;
        state.error = null;

        let actIndex = state.evaluation.evaluationHasActList.findIndex((c) => c.id === act.id);
        if(actIndex === -1){
            state.evaluation.evaluationHasActList.push(act);
            state.evaluation.seanceDetails.find((el) => el.period === act.period).acts.push(act.id);
            return;
        }
        state.evaluation.evaluationHasActList.splice(actIndex, 1, act);
        if(act.caregiver === null) {
            state.evaluation.evaluationHasActList.splice(actIndex, 1);
        }
    },
    setPostAssignActSuccess(state, link) {
        state.evaluation = {
            ...state.evaluation,
            evaluationHasActList: state.evaluation.evaluationHasActList ? [...state.evaluation.evaluationHasActList, link] : [link]
        }
        state.loading = false;
        state.error = null;
    },
    setPostAssignRiskLoading(state, risk) {
        state.evaluation.risks.push(risk);
        state.loading = true;
        state.error = null;
    },
    setPostAssignRiskSuccess(state, risk) {
        state.evaluation.risks.splice(
            state.evaluation.risks.findIndex(item => item.id === risk.tempRiskId),
            1,
            risk.risk
        );
        state.loading = false;
        state.error = null;
    },
    setPutRisksLoading(state, checklist) {
        state.evaluation.risks = state.evaluation.risks.map(item=> {
            return checklist.indexOf(item.id) > -1 ? 
                {
                    ...item,
                    checked: true
                } :
                {
                    ...item,
                    checked: false
                }
        })
        state.loading = true;
        state.error = null;
    },
    setPutRisksSuccess(state, evaluation) {
        state.evaluation = {
            ...state.evaluation,
            risks: evaluation.risks
        }
        state.loading = false;
        state.error = null;
    },
    setPostAssignHabitSuccess(state, assignHabits) {
        state.habits = [];
        state.evaluation.habits = assignHabits.habits ? assignHabits.habits : [];

        state.loading = false;
        state.error = null;
    },
    setGeneralQuestionsSuccess(state, generalQuestions) {
        state.generalQuestions = generalQuestions;
        state.loading = false;
        state.error = null;
    },
    setGeneralAnswerSuccess(state, generalAnswer) {
        state.generalAnswer = generalAnswer;
        state.loading = false;
        state.error = null;
    },
    setPostGeneralQuestionAnswerSuccess(state, evaluation) {
        state.evaluation = {
            ...state.evaluation,
            generalQuestionsAnswers: evaluation.generalQuestionsAnswers
        }
        state.loading = false;
        state.error = null;
    },
    setEvaluationMaterialSuccess(state, evaluationHasMaterialList) {
        state.evaluation.materials = evaluationHasMaterialList.items;
        state.evaluation.materials_ids = evaluationHasMaterialList.items ? evaluationHasMaterialList.items.reduce((acc, obj) => { return [...acc, obj.material ? obj.material.id : []]}, []) : [];
        state.loading = false;
        state.error = null;
    },
    setAddHabit(state, data) {
        for(let period of data.habit.periods) {
            const habit = {
                description: data.habit.description,
                id: null,
                period: period,
                place: data.place
            };
            state.habits.push(habit);
        }
    },
    setRemoveHabit(state, id) {
        state.evaluation.habits = state.evaluation.habits.filter((habit) => habit.id !== id);
    },
    setInitEvaluation(state) {
        const seanceDetails = state.defautSeanceOrder;
        state.evaluation = {
            structureId: '',
            structureName: '',
            agencyId: '',
            evaluateById: '',
            demandDate: moment(new Date()).format('YYYY-MM-DD'),
            evaluationDate: moment(new Date()).format('YYYY-MM-DD'),
            startingDate: moment(new Date()).format('YYYY-MM-DD'),
            endingDate: moment(new Date()).format('YYYY-MM-DD'),
            additionalInfos: '',
            client: '',
            answerList: [],
            evaluationHasActList: [],
            aggirPercentage: 0,
            habits: [],
            risks: [],
            acts: [],
            acts_ids: [],
            materials_ids: [],
            evaluatorComments: "",
            contract: {
                id: null,
                aivqDetails: [],
                avqDetails: [],
                totalAIVQMinutes: "",
                totalAIVQCost: "",
                totalAVQMinutes: "",
                totalAVQCost: "",
                pecDetails: {
                    description: "",
                    type: "",
                    value: ""
                },
            },
            seanceDetails: seanceDetails
        };
        state.error = null;
    },
    setInitTreatment(state){
        state.general = {
            sg01: {
                questionName: 'sg01',
                questionType: 'question',
                response: null
            },
            sg02: {
                questionName: 'sg02',
                questionType: 'question',
                response: null
            },
            sg03: {
                questionName: 'sg03',
                questionType: 'question',
                response: null
            },
            sg04: {
                questionName: 'sg04',
                questionType: 'question',
                response: null
            },
            sg05: {
                questionName: 'sg05',
                questionType: 'question',
                response: null
            },
            sg06: {
                questionName: 'sg06',
                questionType: 'question',
                response: null
            },
            sg07: {
                questionName: 'sg07',
                questionType: 'question',
                response: null
            }
        }
        state.sense = {
            sos01: {
                questionName: 'sos01',
                questionType: 'question',
                response: null
            },
            sos02: {
                questionName: 'sos02',
                questionType: 'question',
                response: null
            },
            sos03: {
                questionName: 'sos03',
                questionType: 'question',
                response: null
            },
            sos04: {
                questionName: 'sos04',
                questionType: 'question',
                response: null
            },
            sos05: {
                questionName: 'sos05',
                questionType: 'question',
                response: null
            },
            sos06: {
                questionName: 'sos06',
                questionType: 'question',
                response: null
            },
            sos07: {
                questionName: 'sos07',
                questionType: 'question',
                response: null
            },
            sos08: {
                questionName: 'sos08',
                questionType: 'question',
                response: null
            }
        }
        state.nutrition= {
            sn01size: {
                questionName: 'sn01size',
                questionType: 'question',
                response: null
            },
            sn01weight: {
                questionName: 'sn01weight',
                questionType: 'question',
                response: null
            },
            sn02: {
                questionName: 'sn02',
                questionType: 'question',
                response: null
            },
            sn03: {
                questionName: 'sn03',
                questionType: 'question',
                response: null
            },
            sn04: {
                questionName: 'sn04',
                questionType: 'question',
                response: null
            },
            sn05: {
                questionName: 'sn05',
                questionType: 'question',
                response: null
            },
            sn06: {
                questionName: 'sn06',
                questionType: 'question',
                response: null
            },
            sn07: {
                questionName: 'sn07',
                questionType: 'question',
                response: null
            },
            sn08: {
                questionName: 'sn08',
                questionType: 'question',
                response: null
            },
            sn09: {
                questionName: 'sn09',
                questionType: 'question',
                response: null
            },
            sn10: {
                questionName: 'sn10',
                questionType: 'question',
                response: null
            },
            sn11: {
                questionName: 'sn11',
                questionType: 'question',
                response: null
            },
            sn12: {
                questionName: 'sn12',
                questionType: 'question',
                response: null
            },
            sn13: {
                questionName: 'sn13',
                questionType: 'question',
                response: null
            },
            sn14: {
                questionName: 'sn14',
                questionType: 'question',
                response: null
            },
            sn15: {
                questionName: 'sn15',
                questionType: 'question',
                response: null
            },
            sn16: {
                questionName: 'sn16',
                questionType: 'question',
                response: null
            },
            sn17: {
                questionName: 'sn17',
                questionType: 'question',
                response: null
            }
        }
        state.breathing = {
            src01: {
                questionName: 'src01',
                questionType: 'question',
                response: null
            },
            src02: {
                questionName: 'src02',
                questionType: 'question',
                response: null
            },
            src03: {
                questionName: 'src03',
                questionType: 'question',
                response: null
            },
            src04: {
                questionName: 'src04',
                questionType: 'question',
                response: null
            },
            src05: {
                questionName: 'src05',
                questionType: 'question',
                response: null
            },
            src06: {
                questionName: 'src06',
                questionType: 'question',
                response: null
            },
            src07: {
                questionName: 'src07',
                questionType: 'question',
                response: null
            },
            src08: {
                questionName: 'src08',
                questionType: 'question',
                response: null
            }
        }
        state.elimination = {
            sfe01: {
                questionName: 'sfe01',
                questionType: 'question',
                response: null
            },
            sfe02: {
                questionName: 'sfe02',
                questionType: 'question',
                response: null
            },
            sfe03: {
                questionName: 'sfe03',
                questionType: 'question',
                response: null
            },
            sfe03b: {
                questionName: 'sfe03b',
                questionType: 'question',
                response: null
            },
            sfe04: {
                questionName: 'sfe04',
                questionType: 'question',
                response: null
            },
            sfe05: {
                questionName: 'sfe05',
                questionType: 'question',
                response: null
            },
            sfe06: {
                questionName: 'sfe06',
                questionType: 'question',
                response: null
            },
            sfe07: {
                questionName: 'sfe07',
                questionType: 'question',
                response: null
            },
            sfe08: {
                questionName: 'sfe08',
                questionType: 'question',
                response: null
            },
        }
        state.motor = {
            sfm01: {
                questionName: 'sfm01',
                questionType: 'question',
                response: null
            },
            sfm02: {
                questionName: 'sfm02',
                questionType: 'question',
                response: null
            },
            sfm03: {
                questionName: 'sfm03',
                questionType: 'question',
                response: null
            },
            sfm04: {
                questionName: 'sfm04',
                questionType: 'question',
                response: null
            },
            sfm05: {
                questionName: 'sfm05',
                questionType: 'question',
                response: null
            },
            sfm06: {
                questionName: 'sfm06',
                questionType: 'question',
                response: null
            },
            sfm07: {
                questionName: 'sfm07',
                questionType: 'question',
                response: null
            },
            sfm08: {
                questionName: 'sfm08',
                questionType: 'question',
                response: null
            },
            sfm101: { //test balance 1
                questionName: 'sfm101',
                questionType: 'question',
                response: null
            },
            sfm102: { //test balance 2
                questionName: 'sfm102',
                questionType: 'question',
                response: null
            },
            sfm103: { //test balance 3
                questionName: 'sfm103',
                questionType: 'question',
                response: null
            },
            sfm11: {
                questionName: 'sfm11',
                questionType: 'question',
                response: null
            }
        }
        state.skin = {
            spt01: {
                questionName: 'spt01',
                questionType: 'question',
                response: null
            },
            spt02: {
                questionName: 'spt02',
                questionType: 'question',
                response: null
            },
            spt03: {
                questionName: 'spt03',
                questionType: 'question',
                response: null
            },
            spt04: {
                questionName: 'spt04',
                questionType: 'question',
                response: null
            },
            spt05: {
                questionName: 'spt05',
                questionType: 'question',
                response: null
            },
        }
        state.pain = {
            sd01: {
                questionName: 'sd01',
                questionType: 'question',
                response: null
            },
            sd02: {
                questionName: 'sd02',
                questionType: 'question',
                response: null
            },
            sd03: {
                questionName: 'sd03',
                questionType: 'question',
                response: null
            },
            sd04: {
                questionName: 'sd04',
                questionType: 'question',
                response: null
            },
            sd05: {
                questionName: 'sd05',
                questionType: 'question',
                response: null
            },
            sd06: {
                questionName: 'sd06',
                questionType: 'question',
                response: null
            },
        }
        state.brain = {
            smd02: {
                questionName: 'smd02',
                questionType: 'question',
                response: null
            },
            smd03: {
                questionName: 'smd03',
                questionType: 'question',
                response: null
            },
            smd04: {
                questionName: 'smd04',
                questionType: 'question',
                response: null
            },
            smd05: {
                questionName: 'smd05',
                questionType: 'question',
                response: null
            },
            smd06: {
                questionName: 'smd06',
                questionType: 'question',
                response: null
            },
            smd07: {
                questionName: 'smd07',
                questionType: 'question',
                response: null
            },
        }
        state.treatment = {
            stsm01: {
                questionName: 'stsm01',
                questionType: 'question',
                response: null
            },
            stsm02: {
                questionName: 'stsm02',
                questionType: 'question',
                response: null
            },
            stsm03: {
                questionName: 'stsm03',
                questionType: 'question',
                response: null
            },
            stsm04: {
                questionName: 'stsm04',
                questionType: 'question',
                response: null
            },
            stsm05: {
                questionName: 'stsm05',
                questionType: 'question',
                response: null
            },
            stsm06: {
                questionName: 'stsm06',
                questionType: 'question',
                response: null
            },
            stsm07: {
                questionName: 'stsm07',
                questionType: 'question',
                response: null
            },
            stsm08: {
                questionName: 'stsm08',
                questionType: 'question',
                response: null
            },
            stsm09: {
                questionName: 'stsm09',
                questionType: 'question',
                response: null
            },
            stsm10: {
                questionName: 'stsm10',
                questionType: 'question',
                response: null
            },
            stsm11: {
                questionName: 'stsm11',
                questionType: 'question',
                response: null
            },
            stsm21: {
                questionName: 'stsm21',
                questionType: 'question',
                response: null
            },
        }
    },
    setEvaluationError(state, error) {
        state.loading = false;
        state.error = error;
    },
    setPostEvaluationSuccess(state, evaluation) {
        state.evaluations.push(evaluation);
        state.loading = false;
        state.error = null;
    },
    setPostAssignMaterialSuccess(state, evaluation) {
        state.evaluation.materials = evaluation.materials ? evaluation.materials : [];
        state.evaluation.materials_ids = evaluation.materials ? evaluation.materials.reduce((acc, obj) => { return [...acc, obj.material ? obj.material.id : []]}, []) : [];

        state.loading = false;
        state.error = null;
    },
    setUpdateMaterial(state, material) {
        const alreadyExistMaterial = state.evaluation.materials.find(mat => mat.material.id === material.id)
        if(!alreadyExistMaterial){
            state.evaluation.materials.push(material)
        }else{
            state.evaluation.materials = state.evaluation.materials.map((element) =>{
                    if (element.material.id === material.id){
                        material.material = element.material
                        return material
                    }
                    return element
                }
            );
        }
        state.evaluation.materials_ids = state.evaluation.materials.reduce((acc, obj) => { return [...acc, obj.material ? obj.material.id : []]}, []);

        state.loading = false;
        state.error = null;
    },
    setPutAggirAnswerSuccess(state, evaluation) {
        //TODO create event buffer
        state.evaluation.answerList = evaluation.answerList;
        state.evaluation.gir = evaluation.gir;
        // const ind = state.evaluation.answerList.findIndex(answer => {
        //     return answer.question.id === newAnswer.question.id && answer.id !== 0;
        // });
        // const stch = newAnswer.answerStch;
        //
        // if (ind === -1) {
        //     state.evaluation.answerList.push({
        //         id: newAnswer.id,
        //         answerStch: stch,
        //         question: {
        //             id: newAnswer.question.id,
        //             item: newAnswer.question.item
        //         },
        //         itemScore: newAnswer.itemScore,
        //         subItemScore: newAnswer.subItemScore
        //     })
        //
        //     state.evaluation.answerList.splice(
        //         state.evaluation.answerList.findIndex(ans => ans.id === 0),
        //         1
        //     );
        // } else {
        //     state.evaluation.answerList[ind].answerStch = stch;
        //     state.evaluation.answerList[ind].itemScore = newAnswer.itemScore;
        //     state.evaluation.answerList[ind].subItemScore =  newAnswer.subItemScore;
        // }
        //
        // if(newAnswer.itemScore) {
        //     for(let i = 0; i< state.evaluation.answerList.length; i++) {
        //         if(state.evaluation.answerList[i].question.item === newAnswer.question.item) {
        //             state.evaluation.answerList[i].itemScore = newAnswer.itemScore;
        //         }
        //     }
        // }
        //
        // state.evaluation.gir = newAnswer.evaluation.gir;

        state.loading = false;
        state.error = null;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setPostDefaultAggirAnswer(state, aggirAnswers) {
        if(aggirAnswers.total === 0) {
            state.loading = false;
            state.error = null;
            return;
        }

        for (let answer of aggirAnswers.items) {
            state.evaluation.answerList.push(answer)
        }

        state.loading = false;
        state.error = null;
    },
    setPutEvaluationSuccess(state, evaluation) {
        state.evaluations.splice(
            state.evaluations.findIndex(evaluationSearch => evaluationSearch.id === evaluation.id),
            1,
            evaluation
        );

        state.loading = false;
        state.error = null;
    },
    setPostEvaluationHasActSuccess(state, evaluationHasAct) {
        const exists = state.evaluation.evaluationHasActList.findIndex(el => el.id === evaluationHasAct.id)
        if(exists) {
            state.evaluation.evaluationHasActList[exists].active = evaluationHasAct.active;
            state.evaluation.evaluationHasActList[exists].caregiver = evaluationHasAct.caregiver;
        } else {
            state.evaluation.evaluationHasActList.push(evaluationHasAct)
        }

        state.loading = false;
        state.error = null;
    },
    setDeleteEvaluationSuccess(state, id) {
        state.evaluations.splice(
            state.evaluations.findIndex(evaluation => evaluation.id === id),
            1
        );
        state.loading = false;
        state.error = null;
    },
    addTreatment(state, treatment) {
        let index = state.treatments.findIndex((c) => c.id === treatment.id);
        if(index === -1){
            state.treatments.push(treatment);
            return;
        }
        state.treatments.splice(index, 1, treatment);
    },
    setRisk(state, risk) {
        state.risk = risk
    },
    setEvaluationHasActSaveSuccess(state, act) {
        if(!act) {
            return;
        }

        const existsEvaluationHasActList = state.evaluation.evaluationHasActList.findIndex(el => el.id === act.id);
        state.evaluation.evaluationHasActList.splice(
            state.evaluation.evaluationHasActList.findIndex(actSearch => actSearch.id === act.id),
            1,
            act
        )

        if(existsEvaluationHasActList !== -1) {
            state.evaluation.evaluationHasActList[existsEvaluationHasActList].active = act.active;
            state.evaluation.evaluationHasActList[existsEvaluationHasActList].caregiver = act.caregiver;
        } else {
            state.evaluation.evaluationHasActList.push(act)
        }

        state.loading = false;
        state.error = null;
    },
    setApplyDaySuccess(state, {ids: ids, selectedDays: selectedDays, period: period}) {
        ids.forEach((id) => {
            let updatedAct = state.evaluation.evaluationHasActList.find(act => act.id === id)
            updatedAct.weekDays = selectedDays;
            updatedAct.period = period;
        })
    },
    setActTimeSum(state, {value, category}) {
        if (category === 'AIVQ') {
            state.aivqTimeSum = value ?? 0
        } else {
            state.avqTimeSum = value ?? 0
        }
    },
    setActPrestationCost(state, {value, category}) {
        if (category === 'AIVQ') {
            state.aivqPrestationCost = value
        } else {
            state.avqPrestationCost = value
        }
    },
    addActRow(state, {row, category}) {
        if (category === 'AIVQ') {
            state.evaluation.contract.aivqDetails.push(row)
        } else {
            state.evaluation.contract.avqDetails.push(row)
        }
    },
    removeActRow(state, {index, category}) {
        if (category === 'AIVQ') {
            state.evaluation.contract.aivqDetails.splice(index, 1)
        } else {
            state.evaluation.contract.avqDetails.splice(index, 1)
        }
    },
    setHeureSem(state, heureSem) {
        state.evaluation.heuresSemaine += heureSem;
    },
    setHeureDim(state, heureDim) {
        state.evaluation.heuresDimanche += heureDim;
    }

}