import { API_URL } from "@/components/Util/EnvVariable";
import { getAsApplicationJson } from '@/api/Fetch';

export const getAppareilMedicaux = async ({ commit }, { idClient: idClient }) => {
    try {
        const response = await getAsApplicationJson(`${API_URL}/appareil-medicaux/${idClient}`);
        const data = await response.json();
        commit("getAppareilMedicaux", data);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export default {
    getAppareilMedicaux,
};