
export default {
    setTerms(state, data) {
        state.terms = data;
    },
    setTermsAccepted(state, data) {
        state.termsAccepted = data === 200;
    },
    setToggleModal(state, data) {
        state.toggleModal = data !== 200;
    }
}