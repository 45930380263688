<template>
    <footer class="footer">
        <div class="footer__container">
          <p class="footer__link"><a href=""  @click.prevent="$router.push('cgu')">Conditions Générales d’Utilisations</a></p>
          <p class="footer__link footer__link--middle"><a href=""  @click.prevent="$router.push('rgpd')">Règlement Générale sur la Protection des Données</a></p>
          <p class="footer__link"><a href=""  @click.prevent="$router.push('mentions')">Mentions Légales</a></p>
          <p class="footer__right">Version 1.0.0</p>
          <p class="footer__right">@ BVIDF OBY 2024 Tous droits réservés</p>
          <cgu-modal/>
        </div>
    </footer>
</template>
<script>

import CguModal from "./CGU/cguModal.vue";

export default {
   name: 'Footer',
  components: {
      CguModal
  },
}
</script>
<style>
 .footer {
    background-color: #E8E7E7;
    z-index: 1;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: flex-end;
 }

 .footer__container {
    display: flex;
    height: fit-content;
    flex-wrap: wrap;
 }

 .footer__link {
    margin: 1rem 0;
    padding: 0 1rem;
 }

 .footer__right {
   margin: 1rem 0;
   padding: 0 1rem;
   border-left: 1px solid #AAAAAA;
 }

 .footer__link--middle {
    border: 1px solid #AAAAAA;
    border-top: 0;
    border-bottom: 0;
 }

</style>