<template>
  <bo-wrap>
    <div class="bo--template">
      <div class="bo--navwrap d-flex">
        <div class="container">
          <nav class="nav bo--nav row" ref="bo--nav">
            <span class="nav-select" :style="style"></span>
            <router-link
                v-if="connectedUser && canAccessItem('CAN_MANAGE_CLIENTS')"
                class="nav-link"
                ref="link-bo-client-list"
                :class="{'active': routeName === 'client-list' || routeName === 'client-form'}"
                :to="{name:'client-list'}">Usager</router-link>
            <router-link
                v-if="connectedUser && canAccessItem('CAN_MANAGE_PROFILS')"
                    class="nav-link"
                    ref="link-bo-profil-list"
                    :class="{'active': routeName === 'profil-list' || routeName === 'profil-form'}"
                    :to="{name:'profil-list'}">Profil</router-link>
            <router-link
                    v-if="connectedUser && canAccessItem('CAN_MANAGE_USERS')"
                    class="nav-link"
                    ref="link-bo-user-list"
                    :class="{'active':routeName === 'user-list' || routeName === 'user-form'}"
                    :to="{name:'user-list'}">Utilisateur</router-link>
            <router-link
                    v-if="connectedUser && canAccessItem('CAN_MANAGE_PRESTATIONS')"
                    class="nav-link"
                    ref="link-bo-prestation-list"
                    :class="{'active':routeName === 'prestation-list' || routeName === 'prestation-form'}"
                    :to="{name:'prestation-list'}">Prestation</router-link>
            <router-link
                    v-if="connectedUser && canAccessItem('CAN_MANAGE_TARIFICATIONS')"
                    class="nav-link"
                    ref="link-bo-tarification-list"
                    :class="{'active':routeName === 'tarification-list' || routeName === 'tarification-form'}"
                    :to="{name:'tarification-list'}">Tarification</router-link>
            <router-link
                    v-if="connectedUser && canAccessItem('CAN_MANAGE_STRUCTURES')"
                    class="nav-link"
                    ref="link-bo-structure"
                    :class="{'active': routeName === 'structure-list' || routeName === 'structure-form'}"
                    :to="{name:'structure-list'}">Structure</router-link>
            <router-link
                    v-if="connectedUser && canAccessItem('CAN_MANAGE_AGENCIES')"
                    class="nav-link"
                    ref="link-bo-agency-list"
                    :class="{'active':routeName === 'agency-list' || routeName === 'agency-form'}"
                    :to="{name:'agency-list'}">Agence</router-link>
            <router-link
                v-if="connectedUser && canAccessItem('CAN_MANAGE_EVALUATIONS')"
                class="nav-link"
                ref="link-bo-evaluation-list"
                :class="{'active':routeName === 'evaluation-list' || routeName === 'evaluation-form'}"
                :to="{name:'evaluation-list'}">Evaluation</router-link>
            <router-link
                v-if="connectedUser && canAccessItem('CAN_MANAGE_QUESTIONS')"
                class="nav-link"
                ref="link-bo-aggir-question-list"
                :class="{'active':routeName === 'aggir-question-list' || routeName === 'aggir-question-form'}"
                :to="{name:'aggir-question-list'}">Questions</router-link>
            <router-link
                v-if="connectedUser && canAccessItem('CAN_MANAGE_MATERIALS')"
                class="nav-link"
                ref="link-bo-material-list"
                :class="{'active':routeName === 'material-list' || routeName === 'material-form'}"
                :to="{name:'material-list'}">Matériels</router-link>
            <router-link
                v-if="connectedUser && canAccessItem('CAN_MANAGE_INTERVENTIONS')"
                class="nav-link"
                ref="link-bo-intervention-list"
                :class="{'active':routeName === 'intervention-list' || routeName === 'intervention-form'}"
                :to="{name:'intervention-list'}">Interventions</router-link>
            <router-link
                v-if="connectedUser && canAccessItem('CAN_MANAGE_STATS')"
                class="nav-link"
                ref="link-bo-statistiques-chart"
                :class="{'active':routeName === 'statistiques-chart'}"
                :to="{name:'statistiques-chart'}">Statistiques</router-link>
            <router-link
                v-if="connectedUser && canAccessItem('CAN_MANAGE_ALERTS')"
                class="nav-link"
                ref="link-bo-alertes-chart"
                :class="{'active':routeName === 'alert'}"
                :to="{name:'alert'}">Alertes SAAD <i class="el-icon-message-solid" :class="getSuperAlertsNotSeen.length>0 ? 'super-alert' : (getAlertsNotSeen.length>0 ? 'unsuper-alert' : '') "></i></router-link>
          </nav>
        </div>
        <div v-if="connectedUser && canAccessItem('CAN_LIST_READ_NOTIFICATION')" class="notification nav bo--nav">
          <a
                class="nav-link cursor-pointer"
                :class="{'active':showAlert === true}"
                @click="showAlert=true">Notifications <i class="el-icon-message-solid" :class="getSuperAlertsNotSeen.length>0 ? 'super-alert' : (getAlertsNotSeen.length>0 ? 'unsuper-alert' : '') "></i></a>
        </div>
      </div>
      <div class="bo--content" v-if="connectedUser && structures">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
        <alert :showAlert="showAlert" @close="showAlert=false">
        </alert>
      </div>
    </div>
  </bo-wrap>
</template>
<script>
import {mapGetters, mapState, mapActions} from "vuex";
import Alert from "@/components/BO/Alert";

export default {
  name: "BOIndex",
  components: {
    Alert
  },
  data() {
    return {
      links: ['client-list', 'profil-list', 'user-list', 'prestation-list', 'tarification-list', 'structure-list', 'agency-list', 'evaluation-list', 'aggir-question-list', 'material-list', 'intervention-list', 'statistiques-chart', 'alert'],
      width: null,
      showAlert:false,
      refreshAlterSetTimeOut: null
    };
  },
  watch: {
    activeLink(v) {
      this.setWidth(v);
    }
  },
  computed: {
    ...mapGetters('User', ['isUserRoleIntervenant', 'isUserRoleOperationManagerOrAreaManager']),
    ...mapGetters('Alert', ['getSuperAlertsNotSeen', 'getAlertsNotSeen']),
    ...mapState("User", ['connectedUser']),
    ...mapState('Structure', ["structures"]),
    routeName() {
      return this.$route.name;
    },
    activeLink() {
      let link = this.routeName;

      if (link === 'agency-form') {
        link = 'agency-list';
      } else if (link === 'user-form') {
        link = 'user-list';
      } else if (link === 'profil-form') {
        link = 'profil-list';
      } else if (link === 'client-form') {
        link = 'client-list';
      } else if (link === 'structure-form') {
        link = 'structure-list';
      } else if (link === 'prestation-form') {
        link = 'prestation-list';
      } else if (link === 'tarification-form') {
        link = 'tarification-list';
      } else if (link === 'contract-form') {
        link = 'contract-list';
      } else if (link === 'evaluation-form') {
        link = 'evaluation-list';
      } else if (link === 'aggir-question-form') {
        link = 'aggir-question-list';
      } else if (link === 'material-form') {
        link = 'material-list';
      } else if (link === 'intervention-form') {
        link = 'intervention-list';
      } else if (link === 'alert-list') {
        link = 'alert-list';
      }

      return this.links.indexOf(link);
    },
    style() {
      return `transform:translateX(${this.width - 15}px)`;
    }
  },
  methods: {
    ...mapActions('Alert', ["getAlerts"]),
    loadAlerts() {
      this.getAlerts({});
    },
    canAccessItem(permission) {
      let storedPermissions = sessionStorage.getItem('permissions');
      let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

      return permissionsArray.includes(permission);
    },
    setWidth(i) {
      if(!this.$refs["bo--nav"]){
        return;
      }
      let w = 0,
        cur = i + 1,
        children = this.$refs["bo--nav"].children;

        setTimeout(()=>{
          for (let index = 1; index < cur; index++) {
            if (children[index]) {
              w += children[index].clientWidth;
            }
          }
          if (children[cur]) {
            w += children[cur].clientWidth * 0.5;
          }
          this.width = w;

        },300)
    },
    auToRefresh(){
      if(this.refreshAlterSetTimeOut){
        clearTimeout(this.refreshAlterSetTimeOut);
      }
      this.loadAlerts();
      this.refreshAlterSetTimeOut = setTimeout(() => {
        this.auToRefresh();
      }, 300000);
    },
  },
  //Hook to move the line active link
  mounted() {
    this.setWidth(this.activeLink);
    this.auToRefresh();
  },
  created(){
    this.setWidth(this.activeLink);
  },
  updated() {
    this.setWidth(this.activeLink);
  },
  beforeDestroy() {
    if(this.refreshAlterSetTimeOut){
      clearTimeout(this.refreshAlterSetTimeOut);
    }
  }
};
</script>
